import { Button, Modal } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import { FC, useEffect } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { useNavigate } from '@modern-js/runtime/router';
import { useRequest } from 'ahooks';
import { QueryOutlineWritingReq, QueryOutlineWritingResp, RemoveOutlineWritingReq } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import SiderList from '../sider-list';
import AiWritingStore, { SiderType } from '@/store/aiWritingStore';
import useUrlParams from '@/hooks/useUrlParams';
import GlobalStore from '@/store/globalStore';
import useLocale from '@/hooks/useLocale';
import { copyOutlineWriting, createOutlineWriting, queryOutlineWriting, removeOutlineWriting, updateOutlineWritingName } from '@/api/copilot_api';

interface IndexPros {}

const SiderAdvanced: FC<IndexPros> = () => {
  const locale = useLocale();
  const navigate = useNavigate();

  const limit = 5;
  const routePath = '/advanced-writing';
  const isAdvancedWriting = location.href.includes(routePath);
  // 获取url中的aid
  const { aid = '' } = useUrlParams();

  const [{ advanceList, adTotalCount, adCurrentIndex, advanceReqComplete }, { setAdvanceList, setAdTotalCount, setAdCurrentIndex, setAdvanceReqComplete }] =
    useModel(AiWritingStore);

  const [{ sideBarReqFinish }] = useModel(GlobalStore);

  // 获取长文写作列表
  const { run: runQueryOutlineWriting } = useRequest(
    async (params: QueryOutlineWritingReq, _cb?: (r: QueryOutlineWritingResp) => void) => {
      return await queryOutlineWriting(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        setAdvanceReqComplete(true);
        if (params[1]) {
          params[1](res);
          return;
        }
        const { articles = [], total = 0, page = 1 } = res;
        if (total === 0) {
          // 没有列表，创建一个
          runCreateOutlineWriting({});
          return;
        }
        // 没有aid，重新定向path
        if (!aid && isAdvancedWriting) {
          setAdvanceReqComplete(false);
          navigate(`${routePath}?aid=${articles[0].articleId}`, { replace: true });
          return;
        }
        setAdvanceList(articles);
        setAdTotalCount(total);
        setAdCurrentIndex(page);
      },
      onError: (error: any) => {
        // 当url里面的aid不在所有的list里面的时候，会报错802
        if (error.code === 802 && isAdvancedWriting) {
          setAdvanceReqComplete(false);
          navigate(routePath);
        }
      },
    },
  );

  // 新建长文写作列表项
  const { run: runCreateOutlineWriting } = useRequest(createOutlineWriting, {
    manual: true,
    onSuccess: res => {
      // 新建列表项要跳转到对应的带aid的path
      setAdvanceReqComplete(false);
      navigate(`${routePath}?aid=${res.articleId}`, { replace: true });
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 编辑长文写作列表项
  const { run: runUpdateOutlineWritingName } = useRequest(updateOutlineWritingName, {
    manual: true,
    onSuccess: (_res, param) => {
      // 如果当前列表不止一个，更新列表
      runQueryOutlineWriting({ lastId: param[0].articleId, limit: 5 });
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 复制长文写作列表项
  const { run: runCopyOutlineWriting } = useRequest(copyOutlineWriting, {
    manual: true,
    onSuccess: () => {
      // 复制列表项，列表项会在page：1的第一个，跳转到不带aid的path，会重新定向到带第一个aid的path
      setAdvanceReqComplete(false);
      navigate(routePath);
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 删除长文写作列表项
  const { run: runRemoveOutlineWriting } = useRequest(removeOutlineWriting, {
    manual: true,
    onSuccess: (_res, param) => {
      if (aid === param[0].articleId && isAdvancedWriting) {
        const fIndex = advanceList.findIndex(item => item.articleId === param[0].articleId);
        if (advanceList.length === 1 && fIndex === 0) {
          // 如果删除的是选中项本身，并且列表只有一项，则跳转他上一页，定位最后一个
          runQueryOutlineWriting({ page: adCurrentIndex - 1, limit }, res => {
            const { articles = [] } = res;
            setAdvanceReqComplete(false);
            navigate(`${routePath}?aid=${articles[articles.length - 1].articleId}`, { replace: true });
          });
        } else if (fIndex !== advanceList.length - 1) {
          // 如果删除的是选中项本身，并且列表有多项，并且不是最后一个，定位当前项的下一个
          setAdvanceReqComplete(false);
          navigate(`${routePath}?aid=${advanceList[fIndex + 1].articleId}`, { replace: true });
        } else {
          // 如果删除的是选中项本身，并且列表有多项，并且是最后一个，定位当前项的下一个
          setAdvanceReqComplete(false);
          navigate(`${routePath}?aid=${advanceList[fIndex - 1].articleId}`, { replace: true });
        }
      } else {
        // 如果删除的不是他本身，刷新列表
        runQueryOutlineWriting({ page: advanceList.length === 1 ? adCurrentIndex - 1 : adCurrentIndex, limit });
      }
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 删除
  const remove = (articleInfo: RemoveOutlineWritingReq) => {
    if (adTotalCount < 2) {
      return;
    }
    Modal.confirm({
      title: locale.ad_writing_confirm_delete_title,
      content: <div className="text-center text-sm text-[var(--color-text-1)]">{locale.ad_writing_confirm_delete_content}</div>,
      okButtonProps: {
        status: 'danger',
      },
      onOk: () => runRemoveOutlineWriting(articleInfo),
    });
  };

  useEffect(() => {
    if (advanceReqComplete) {
      return;
    }
    if (sideBarReqFinish) {
      runQueryOutlineWriting(aid && isAdvancedWriting ? { lastId: aid, limit } : { limit, page: 1 });
    }
  }, [aid, sideBarReqFinish]);

  return (
    <div>
      <div className="section">
        <p className="section-title">{locale.ad_writing}</p>
        <Button
          shape="circle"
          size="mini"
          type="primary"
          icon={<IconPlus />}
          onClick={() => {
            runCreateOutlineWriting({});
          }}
        />
      </div>
      <SiderList
        onDelete={value => {
          remove(value);
        }}
        onEdit={value => {
          runUpdateOutlineWritingName(value);
        }}
        onCopy={value => {
          runCopyOutlineWriting(value);
        }}
        onClick={value => {
          setAdvanceReqComplete(false);
          navigate(`${routePath}?aid=${value.articleId}`, { replace: true });
        }}
        paginationChange={pageNumber => {
          setAdCurrentIndex(pageNumber);
          runQueryOutlineWriting({ page: pageNumber, limit: 5 });
        }}
        currentPage={adCurrentIndex}
        pageSize={limit}
        sourceData={advanceList}
        totalCount={adTotalCount}
        siderType={SiderType.ADVANCED}
        isPersistence={true}
      />
    </div>
  );
};

export default SiderAdvanced;
