import { model } from '@modern-js/runtime/model';
import { ChatMessage, Role } from '@/components/ai-chat-message';

interface Props {
  chatMessage: ChatMessage[];
  currentConversationId: string;
  showQuote: boolean;
  quoteRect: {
    top: number;
    left: number;
  };
  quoteText: string;
  replyQuote: string;
}

const AiBotStore = model<Props>('AiBotStore').define({
  state: {
    chatMessage: [],
    currentConversationId: '',
    showQuote: false,
    quoteRect: null,
    quoteText: '', // 实时选取出的quote内容
    replyQuote: '', // 点击reply后回复框内的quote内容
  },
  actions: {
    setCurrentConversationId(draft, payload) {
      draft.currentConversationId = payload;
    },

    // clear message
    clearChatMessage(draft, payload) {
      draft.chatMessage = payload;
    },
    setShowQuote(draft, payload) {
      draft.showQuote = payload;
    },
    setQuoteRect(draft, payload) {
      draft.quoteRect = payload;
    },
    setQuoteText(draft, payload) {
      draft.quoteText = payload;
    },
    setReplyQuote(draft, payload) {
      draft.replyQuote = payload;
    },
    // 重新生成
    reRestChatMessage(draft, payload) {
      const oldMessage = draft.chatMessage;
      const lastUserContext = oldMessage.find(chat => chat.id === payload.id && chat.role === Role.user) || {};
      draft.chatMessage = [
        ...oldMessage.filter(item => item.id !== payload.id),
        { ...lastUserContext, status: 'loading' },
        {
          conversationId: lastUserContext?.conversationId,
          id: lastUserContext?.id,
          answer: '',
          status: 'loading',
          role: Role.system,
        },
      ];
    },

    // add new message
    addChatMessage(draft, payload: ChatMessage[]) {
      draft.chatMessage = [...draft.chatMessage, ...payload];
    },

    setNewChatMessage(draft, payload: ChatMessage) {
      draft.currentConversationId = payload.conversationId || '';
      const oldMessage = draft.chatMessage;
      draft.chatMessage = oldMessage.map(item => {
        if ((!item.status || item.status === 'loading') && item.role === Role.user) {
          return { ...item, id: payload.id || '', conversationId: payload.conversationId };
        }
        if (item.status === 'loading' && item.role === Role.system) {
          return { ...item, status: payload.status, id: payload.id || '', conversationId: payload.conversationId };
        }
        return item;
      });
    },

    updateMessage(draft, payload: ChatMessage) {
      const oldMessage = draft.chatMessage;
      draft.chatMessage = oldMessage.map(item =>
        item.conversationId === payload.conversationId && item.status === 'incomplete' && item.role === Role.system ? { ...item, answer: `${item.answer}${payload.answer}` } : item,
      );
    },

    completeChatMessage(draft, payload: ChatMessage) {
      const oldMessage = [...draft.chatMessage];
      draft.chatMessage = oldMessage.map(item => (item.conversationId === draft.currentConversationId ? { ...item, status: payload.status } : item));
    },

    resetCurrentConversationId(draft) {
      draft.currentConversationId = '';
    },

    removeLastChatMessage(draft) {
      const oldMessage = [...draft.chatMessage];
      const lastChatMessage = oldMessage[oldMessage.length - 1];
      //   console.log('oldMessage===>', JSON.stringify(oldMessage), 'lastChatMessage===>', JSON.stringify(lastChatMessage));
      const userMessage = oldMessage.find(item => item?.id === lastChatMessage?.id && item.role === Role.user);
      //   console.log('userMessage===>', JSON.stringify(userMessage));
      draft.chatMessage = [...oldMessage.filter(chat => chat?.id !== lastChatMessage?.id), { ...userMessage, status: 'error' }];
    },
  },
});

export default AiBotStore;
