import './index.scss';

import { Dropdown, Menu } from '@arco-design/web-react';
import { useContext, useMemo } from 'react';
import { IconCheck } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/routes/context';

const MultiLanguageDropdown = () => {
  const { setLang, lang } = useContext(GlobalContext);

  const langVal = useMemo(() => {
    let val = '简';
    switch (lang) {
      case 'en-US':
        val = 'EN';
        break;
      case 'zh-HK':
        val = '繁';
        break;
      default:
        val = '简';
        break;
    }
    return val;
  }, [lang]);

  return (
    <>
      <div className="user-setting-bar">
        <Dropdown
          position="bottom"
          droplist={
            <Menu
              className="language-menu"
              onClickMenuItem={key => {
                setLang?.(key);
              }}
            >
              <Menu.Item key="en-US" className="language-menu-item">
                {lang === 'en-US' ? <IconCheck /> : <span>&nbsp;</span>}English
              </Menu.Item>
              <Menu.Item key="zh-HK" className="language-menu-item">
                {lang === 'zh-HK' ? <IconCheck /> : <span>&nbsp;</span>}繁體
              </Menu.Item>
              <Menu.Item key="zh-CN" className="language-menu-item">
                {lang === 'zh-CN' ? <IconCheck /> : <span>&nbsp;</span>}简体
              </Menu.Item>
            </Menu>
          }
        >
          <div className={'bar-box'}>{langVal}</div>
        </Dropdown>
      </div>
    </>
  );
};

export default MultiLanguageDropdown;
