import { useContext, useEffect, useMemo, useRef } from 'react';
import { useModel } from '@modern-js/runtime/model';
import dayjs from 'dayjs';
import { useLocation } from '@modern-js/runtime/router';
import SidebarDrawer from '@/components/sidebar-drawer';
import SidebarContent from '@/components/sidebar-content';
import GlobalStore from '@/store/globalStore';
import { SidebarType } from '@/type/copilot';
import Proofreading from '@/components/proofreading';
import Feedback from '@/components/feedback';
import EditorStore from '@/store/editorStore';
import useControlAIWritingInput from '@/hooks/useControlAIWritingInput';
import { GlobalContext } from '@/routes/context';
import { AiBotEvent, ModuleName } from '@/config/track.config';

const GlobalRightSider = () => {
  const aiBotTimeRef = useRef(dayjs().unix());

  const [
    { sideBar: configSideBar = [], aiBotVisible, sidebarType = SidebarType.UNKNOWN, activeAiBot, isSiderBarDrawerVisible },
    { changeSideBarType, changeActiveAiBot, changeAiBotVisible },
  ] = useModel(GlobalStore);
  const [{ visibleGInput, writingParamEntered }] = useModel(EditorStore);

  const { open: openAIWriting, close: closeAIWriting, setWritingSence } = useControlAIWritingInput();

  const { collectEvent } = useContext(GlobalContext);

  const renderSidBar = useMemo(() => {
    switch (sidebarType) {
      case SidebarType.PROOFREADING:
        return <Proofreading />;
      case SidebarType.FEEDBACK:
        return <Feedback />;
      default:
        return <></>;
    }

    // return sidebarMap[sidebarType];
  }, [sidebarType]);

  const handleCloseSidebar = () => {
    changeSideBarType(SidebarType.UNKNOWN);
  };

  const handleSidebarClick = (type: SidebarType) => {
    if (sidebarType === type) {
      handleCloseSidebar();
      return;
    }
    changeSideBarType(type);
    // AI 写作
    if (type === SidebarType.COPILOT) {
      const isAIWritingInputOpen = visibleGInput;
      const keepOpening = isAIWritingInputOpen && writingParamEntered;
      if (keepOpening) {
        return;
      }

      // 打开行内 写作引导框体
      if (!isAIWritingInputOpen) {
        openAIWriting();
      } else {
        // 关闭写作框体
        closeAIWriting();
      }
      setWritingSence('');
      return;
    }
    closeAIWriting();
  };

  // 长文写作没有AI校对，临时处理掉
  const { pathname } = useLocation();
  const sideBar = useMemo(() => {
    return configSideBar.filter(side => {
      if (pathname.includes('/advanced-writing')) {
        return side.type !== SidebarType.PROOFREADING;
      }
      return true;
    });
  }, [configSideBar, pathname]);

  // pathname改变时收回抽屉
  useEffect(() => {
    if (isSiderBarDrawerVisible) {
      handleCloseSidebar();
    }
  }, [pathname]);
  return (
    <div className="flex h-full">
      <SidebarDrawer visible={isSiderBarDrawerVisible} onClose={handleCloseSidebar}>
        {renderSidBar}
      </SidebarDrawer>
      <SidebarContent
        sideBar={sideBar}
        isAiBotActivate={activeAiBot}
        type={sidebarType}
        onClick={handleSidebarClick}
        onAiChat={() => {
          if (activeAiBot) {
            collectEvent?.([
              {
                event: AiBotEvent.CLICK_HIDE_AI_BOT,
                params: {
                  moduleName: ModuleName.AI_BOT,
                  duration: `${dayjs().unix() - aiBotTimeRef.current}`,
                },
              },
            ]);
          } else {
            aiBotTimeRef.current = dayjs().unix();
            collectEvent?.([
              {
                event: AiBotEvent.CLICK_SHOW_AI_BOT,
                params: {
                  moduleName: ModuleName.AI_BOT,
                },
              },
            ]);
          }
          changeActiveAiBot(!activeAiBot);
          changeAiBotVisible(!aiBotVisible);
        }}
      />
    </div>
  );
};

export default GlobalRightSider;
