import './index.scss';
import { useState, useRef, useEffect, FC } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { IconAdvancedIcon, IconAiwritingIcon, IconTrash, IconEditBtn, IconCopy } from '@arco-iconbox/react-hkgai-government-icon';
import { Input, List, Typography } from '@arco-design/web-react';
import { ArticleInfo } from '@/type/copilot';
import useLocale from '@/hooks/useLocale';
import useUrlParams from '@/hooks/useUrlParams';
import { SiderType } from '@/store/aiWritingStore';

//
interface IndexPros {
  onCopy?: (value: ArticleInfo) => void;
  onEdit?: (value: ArticleInfo) => void;
  onDelete?: (value: ArticleInfo) => void;
  onClick?: (value: ArticleInfo) => void;
  paginationChange?: (pageNumber: number, pageSize: number) => void;
  currentPage?: number;
  pageSize?: number;
  sourceData?: ArticleInfo[];
  totalCount: number;
  siderType?: SiderType;
  isPersistence?: boolean;
}

const SiderList: FC<IndexPros> = ({
  onEdit,
  onDelete,
  onCopy,
  onClick,
  paginationChange,
  currentPage,
  pageSize,
  sourceData,
  totalCount,
  siderType = SiderType.AIWRITING,
  isPersistence = false,
}) => {
  const { aid = '' } = useUrlParams();
  const [touchIndex, setTouchIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const locale = useLocale();

  const inputRef = useRef<any>(undefined);

  useEffect(() => {
    if (editIndex >= 0) {
      inputRef.current?.focus();
    }
  }, [editIndex]);

  const update = (item: ArticleInfo, value: string) => {
    const temp = item;
    temp.title = value;
    onEdit?.({ ...item, title: value });
    setEditIndex(-1);
  };

  return (
    <div className="sider-page">
      <List
        className={'sider-list'}
        dataSource={sourceData}
        pagination={
          (sourceData?.length || 0) > 0
            ? {
                ...(isPersistence ? { current: currentPage } : { defaultCurrent: currentPage }),
                pageSize,
                total: isPersistence ? totalCount : sourceData?.length || 0,
                size: 'small',
                simple: true,
                showTotal: false,
                sizeCanChange: false,
                hideOnSinglePage: true,
                onChange: paginationChange,
              }
            : undefined
        }
        bordered={false}
        render={(item, index) => (
          <div
            key={`${item.articleId}`}
            className={classNames('sider-list-item', {
              'sider-list-item-dis': touchIndex === index || aid === item.articleId,
            })}
            onMouseEnter={() => {
              setTouchIndex(index);
            }}
            onMouseLeave={() => {
              setTouchIndex(-1);
            }}
            onClick={() => {
              onClick?.(item);
            }}
          >
            <div className="item-body-view">
              <div className="item-top-view">
                <div className="icon-view">{siderType === SiderType.AIWRITING ? <IconAiwritingIcon className="icon" /> : <IconAdvancedIcon className="icon" />}</div>
                <div className="content">
                  {editIndex === index ? (
                    <Input
                      defaultValue={item.title}
                      ref={inputRef}
                      onPressEnter={event => {
                        update({ articleId: item.articleId }, event.target.value);
                      }}
                      onBlur={event => {
                        update({ articleId: item.articleId }, event.target.value);
                      }}
                    />
                  ) : (
                    <Typography.Ellipsis className="title" rows={1} showTooltip>
                      {item.title || locale.ad_writing_article_default_title}
                    </Typography.Ellipsis>
                  )}
                </div>
                <div className="menu" style={{ display: touchIndex === index && editIndex !== index ? 'flex' : 'none', flexDirection: 'row-reverse' }}>
                  <IconTrash
                    className={classNames('delete-icon', { 'delete-icon-dis': totalCount < 2 })}
                    onClick={event => {
                      event.stopPropagation();
                      onDelete?.(item);
                    }}
                  />
                  <IconEditBtn
                    className="edit-icon"
                    onClick={event => {
                      event.stopPropagation();
                      setEditIndex(index);
                    }}
                  />

                  {siderType === SiderType.ADVANCED ? (
                    <IconCopy
                      className="copy-icon"
                      onClick={event => {
                        event.stopPropagation();
                        onCopy?.(item);
                      }}
                    />
                  ) : undefined}
                </div>
              </div>
              <div className="item-bottom-view">{dayjs.unix(Number(item.updateTimeSec ?? 0)).format('YYYY.MM.DD HH:mm') || '--'}</div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default SiderList;
