import { Button, Modal } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import { FC, useEffect, useRef } from 'react';

import { useNavigate } from '@modern-js/runtime/router';
import { useModel } from '@modern-js/runtime/model';
import { useLocalStorageState, useMemoizedFn, useRequest } from 'ahooks';
import { CreateAndSaveAiWritingReq, QueryAiWritingReq, QueryAiWritingResp, QueryAiWritingResp_ArticleInfo, RemoveAiWritingReq } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { cloneDeep, throttle } from 'lodash-es';
import { EDITOR_EVENT } from '@hkgai/slate-plugin/dist/lib/core';
import { Editor, Transforms } from '@hkgai/slate-plugin/dist/lib/delta';
import { PROOFREAD_LINE_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import SiderList from '../sider-list';
import AiWritingStore, { SiderType } from '@/store/aiWritingStore';
import useLocale from '@/hooks/useLocale';
import GlobalStore from '@/store/globalStore';
import { createAndSaveAiWriting, queryAiWriting, removeAiWriting } from '@/api/copilot_api';
import { useWritingContext } from '@/context/writingContext';
import { useArticleId } from '@/hooks/article';
import { SidebarType } from '@/type/copilot';
import EditorStore from '@/store/editorStore';
import { AIWorkStatus } from '@/type/ui';

interface IndexPros {
  isHaveADWriting: boolean;
  isPersistence?: boolean;
}

const SiderAiWriting: FC<IndexPros> = ({ isHaveADWriting = true, isPersistence = false }) => {
  const locale = useLocale();
  const navigate = useNavigate();
  const limit = isHaveADWriting ? 5 : 10;
  const routePath = '/writing';
  const isAiWriting = window.location.href.includes(routePath);
  const aid = useArticleId();
  // 本地化存储的key
  const userID = `copilot_${localStorage.getItem('USER_ID') ?? ''}`;
  const [aiList, setAilist] = useLocalStorageState<QueryAiWritingResp_ArticleInfo[]>(userID, {
    defaultValue: [],
    listenStorageChange: true,
  });
  const canSave = useRef(false);
  // 文档的初始内容是否加载完
  const isContentLoaded = useRef(false);
  const timer = useRef<any>(null);

  const [{ aiWritingList, currentIndex, totalCount, aiWritingReqComplete }, { setAiWritingList, setTotalCount, setCurrentIndex, setAiWritingReqComplete, setIsLocalStorge }] =
    useModel(AiWritingStore);
  const { editor, editorKit } = useWritingContext();
  const [{ sideBarReqFinish, sidebarType }] = useModel(GlobalStore);
  const [{ workStatus }] = useModel(EditorStore);

  // 获取AI写作列表
  const { run: runQueryAiWriting } = useRequest(
    async (params: QueryAiWritingReq, _cb?: (r: QueryAiWritingResp) => void) => {
      return await queryAiWriting(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        setAiWritingReqComplete(true);
        if (params[1]) {
          params[1](res);
          return;
        }
        const { articles = [], total = 0, page = 1 } = res;
        if (total === 0) {
          // 没有列表，创建一个
          creatAndUpdate({});
          return;
        }
        // 没有aid，重新定向path
        if (!aid && isAiWriting) {
          setAiWritingReqComplete(false);
          navigate(`${routePath}?aid=${articles[0].articleId}`, { replace: true });
          return;
        }
        setAiWritingList(articles);
        setTotalCount(total);
        setCurrentIndex(page);
      },
      onError: (error: any) => {
        // 当url里面的aid不在所有的list里面的时候，会报错802
        if (error.code === 802 && isAiWriting) {
          setAiWritingReqComplete(false);
          navigate(routePath);
        }
      },
    },
  );

  // 请求列表
  const getAiWritingList = (param?: { page?: number; articleId?: string }) => {
    const { page = 1, articleId } = param || {};
    if (!isPersistence) {
      setAiWritingReqComplete(true);
      const list = cloneDeep(aiList || []).sort((a, b) => Number(b.updateTimeSec) - Number(a.updateTimeSec));
      // 没有列表，创建一个
      if (list.length === 0) {
        creatAndUpdate({});
        return;
      }
      // url里面没有aid，但是有列表，直接跳转到第一个
      if (!aid && isAiWriting) {
        setAiWritingReqComplete(false);
        navigate(`${routePath}?aid=${list[0].articleId}`, { replace: true });
        return;
      }
      setAiWritingList(list);
      setTotalCount(list.length);
      setCurrentIndex(page);
    } else {
      runQueryAiWriting(articleId && isAiWriting ? { lastId: articleId, limit } : { page, limit });
    }
  };

  // 新建Ai写作列表项 或 修改AI写作列表项
  const { run: runCreateAndSaveAiWriting } = useRequest(createAndSaveAiWriting, {
    manual: true,
    throttleWait: 500,
    onSuccess: (res, params) => {
      // params有值表明是save，没有值表明是create
      if (Object.keys(params[0]).length) {
        const newAiWritingList = aiWritingList.map(item => (item.articleId === params[0].articleId ? { ...item, ...params[0] } : item));
        setAiWritingList(newAiWritingList);
      } else {
        // create的时候，增项在列表开头，url更新aid
        setAiWritingReqComplete(false);
        navigate(`${routePath}?aid=${res.articleId}`, { replace: true });
      }
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 新建或保存
  const creatAndUpdate = (articleInfo: CreateAndSaveAiWritingReq = {}) => {
    if (!isPersistence) {
      const articleInfoTemp = {
        articleId: Date.now().toString(),
        createTimeSec: (Date.now() / 1000).toFixed(), // 使用当前时间戳作为创建时间
        updateTimeSec: (Date.now() / 1000).toFixed(), // 使用当前时间戳作为创建时间
        content: '',
        title: '',
      };
      const list = cloneDeep(aiList || []);
      if (Object.keys(articleInfo).length) {
        // 传参数进来就是保存
        const newList = list.map(item => {
          if (item.articleId === articleInfo.articleId) {
            return { ...item, ...articleInfo, updateTimeSec: (Date.now() / 1000).toFixed() };
          }
          return item;
        });
        setAilist(newList);
        setAiWritingList(newList);
      } else {
        list.unshift(articleInfoTemp);
        setAilist(list);
        setAiWritingList(list);
        setTotalCount(list.length);
        setAiWritingReqComplete(false);
        navigate(`${routePath}?aid=${articleInfoTemp.articleId}`, { replace: true });
        // if (isAiWriting) {}
      }
    } else {
      runCreateAndSaveAiWriting(Object.keys(articleInfo).length ? articleInfo : {});
    }
  };

  // 删除AI写作列表项
  const { run: runRemoveAiWriting } = useRequest(removeAiWriting, {
    manual: true,
    onSuccess: (_res, param) => {
      if (aid === param[0].articleId && isAiWriting) {
        const fIndex = aiWritingList.findIndex(item => item.articleId === param[0].articleId);
        if (aiWritingList.length === 1 && fIndex === 0) {
          // 如果删除的是选中项本身，并且列表只有一项，则跳转他上一页，定位最后一个
          runQueryAiWriting({ page: currentIndex - 1, limit }, res => {
            const { articles = [] } = res;
            setAiWritingReqComplete(false);
            navigate(`${routePath}?aid=${articles[articles.length - 1].articleId}`, { replace: true });
          });
        } else if (fIndex !== aiWritingList.length - 1) {
          // 如果删除的是选中项本身，并且列表有多项，并且不是最后一个，定位当前项的下一个
          setAiWritingReqComplete(false);
          navigate(`${routePath}?aid=${aiWritingList[fIndex + 1].articleId}`, { replace: true });
        } else {
          // 如果删除的是选中项本身，并且列表有多项，并且是最后一个，定位当前项的下一个
          setAiWritingReqComplete(false);
          navigate(`${routePath}?aid=${aiWritingList[fIndex - 1].articleId}`, { replace: true });
        }
      } else {
        // 如果删除的不是他本身，刷新列表
        runQueryAiWriting({ page: aiWritingList.length === 1 ? currentIndex - 1 : currentIndex, limit });
      }
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  // 删除
  const remove = (articleInfo: RemoveAiWritingReq) => {
    if (totalCount < 2) {
      return;
    }

    Modal.confirm({
      title: locale.ad_writing_confirm_delete_title,
      content: locale.ad_writing_confirm_delete_content,
      okButtonProps: {
        status: 'danger',
      },
      onOk: () => {
        if (!isPersistence) {
          const list = cloneDeep(aiList || []);

          list.forEach((item, index) => {
            if (item.articleId === articleInfo.articleId) {
              list.splice(index, 1);
              setAilist(list);
              if (aid === articleInfo.articleId && isAiWriting) {
                // 如果删除的是他本身，则跳转他上一个
                setAiWritingReqComplete(false);
                navigate(`${routePath}?aid=${list[index - 1 > -1 ? index - 1 : 0].articleId}`, { replace: true });
                return;
              }
              setAiWritingList(list);
              setTotalCount(list.length);
            }
          });
        } else {
          runRemoveAiWriting(articleInfo);
        }
      },
    });
  };

  useEffect(() => {
    if (aiWritingReqComplete) {
      return;
    }
    if (sideBarReqFinish) {
      getAiWritingList({
        articleId: aid,
      });
    }
  }, [aid, sideBarReqFinish]);

  useEffect(() => () => {
    setAiWritingReqComplete(false);
  });

  // 保存
  const { run: runSave } = useRequest(createAndSaveAiWriting, {
    manual: true,
    onSuccess: res => {
      console.log('res======>', res);
    },
    onError: error => {
      console.log('error======>', error);
    },
  });

  const save = useMemoizedFn(({ content, articleId }) => {
    const fItem = aiWritingList.find(item => item.articleId === articleId);
    if (!fItem) {
      return;
    }
    if (!isPersistence) {
      const newList = aiWritingList.map(item => {
        if (item.articleId === fItem.articleId) {
          return { ...fItem, content, updateTimeSec: (Date.now() / 1000).toFixed() };
        }
        return item;
      });
      setAilist(newList);
    } else {
      runSave({
        ...fItem,
        content,
        articleId,
      });
    }
  });

  const throttleSave = throttle(save, 1000);

  useEffect(() => {
    const updateHandler = () => {
      if (!canSave.current || sidebarType === SidebarType.PROOFREADING) {
        return;
      }

      // 有校对的下划线的时候，不保存
      const proofreadLineGroup = editor?.nodes({
        at: [],
        match: node => Object.prototype.hasOwnProperty.call(node, PROOFREAD_LINE_KEY),
      });
      if (proofreadLineGroup && Array.from(proofreadLineGroup).length > 0) {
        return;
      }
      // 保存
      // 不加定时器editor?.children获取不到最新值
      setTimeout(() => {
        const content = editor?.children ? JSON.stringify(editor.children) : '';
        throttleSave({ content, articleId: aid });
      }, 0);
    };
    editorKit?.event.on(EDITOR_EVENT.CONTENT_CHANGE, updateHandler);
    return () => {
      editorKit?.event.off(EDITOR_EVENT.CONTENT_CHANGE, updateHandler);
      throttleSave.flush();
    };
  }, [editorKit?.event, editor, sidebarType, aiWritingList]);

  useEffect(() => {
    // isContentLoaded 判断文档是否初始化过，重复初始化会覆盖富文本编辑器内容而影响正在插入内容的操作
    if (aid && editor && aiWritingList.length && !isContentLoaded.current) {
      const currentArticle = aiWritingList.find(e => e.articleId === aid);
      const isEditorInserting = workStatus === AIWorkStatus.WORKING || workStatus === AIWorkStatus.WORK_PENDING;
      if (currentArticle) {
        if (currentArticle?.content && !isEditorInserting) {
          try {
            const slateNodes = JSON.parse(currentArticle.content);
            Transforms.delete(editor, {
              at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
              },
            });
            editor.insertNodes(slateNodes, {
              at: [0],
            });
            // insertNodes最底下会多一个空段落，清除它
            editor.removeNodes({ at: [editor.children.length - 1] });
            // 赋值会增加history，清除它
            editorKit?.track.clear();

            // 滚动页面到起始位置
            Transforms.deselect(editor);
          } catch (error) {
            console.error('[load slate initial nodes error]', error);
          }
        }
        isContentLoaded.current = true;
        // 初始化完毕后打开保存开关
        if (!canSave.current) {
          // 给编辑器赋值会触发contentChange事件，为了防止初次给编辑器赋值的时候就触发save，所以延迟1秒
          timer.current = setTimeout(() => {
            canSave.current = true;
          }, 1000);
        }
      }
    }
    return () => {
      clearTimeout(timer.current);
      canSave.current = false;
      isContentLoaded.current = false;
    };
  }, [aid, aiWritingList, editor]);

  return (
    <div>
      <div className="section">
        <p className="section-title">{locale.sidebar_ai_writing}</p>
        <Button
          shape="circle"
          size="mini"
          type="primary"
          icon={<IconPlus />}
          onClick={() => {
            creatAndUpdate({});
          }}
        />
      </div>
      <SiderList
        onDelete={value => {
          remove(value);
        }}
        onEdit={value => {
          creatAndUpdate(value);
        }}
        onClick={value => {
          if (aid === value.articleId) {
            return;
          }
          setAiWritingReqComplete(false);
          navigate(`${routePath}?aid=${value.articleId}`, { replace: true });
        }}
        paginationChange={pageNumber => {
          setCurrentIndex(pageNumber);
          getAiWritingList({ page: pageNumber });
        }}
        currentPage={currentIndex}
        pageSize={limit}
        sourceData={aiWritingList || []}
        totalCount={totalCount}
        siderType={SiderType.AIWRITING}
        isPersistence={isPersistence}
      />
    </div>
  );
};

export default SiderAiWriting;
