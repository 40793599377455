import { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Link, Checkbox } from '@arco-design/web-react';
import { matchName } from '../utils';
import { PCCategory, PCMatchParagraph } from '@/type/proofread';
import { GlobalContext } from '@/routes/context';
import { ModuleName, ProofreadingEvent } from '@/config/track.config';

interface CatalogNum {
  name?: string;
  count?: number;
  item: PCMatchParagraph;
}

const SummarizeTab: FC<{
  items: PCMatchParagraph[];
  mainCatalog?: string[];
  subCatalog?: string;
  onCatalogChange?: (type: 'main' | 'sub', catalogValue?: string[]) => void;
  onIgnore?: (type: 'main' | 'sub' | 'total', catalogValue?: string) => void;
  locale?: any;
}> = ({ items, mainCatalog = [], subCatalog, onCatalogChange, onIgnore, locale }) => {
  const { lang = 'en-US', collectEvent } = useContext(GlobalContext);

  const catalogButton = (infos: CatalogNum[], type: 'main' | 'sub') => {
    return infos
      .sort((a, b) => {
        return a.item.issue!.category!.length - b.item.issue!.category!.length;
      })
      .map((info, index) => {
        return (
          <Checkbox
            key={index}
            onChange={value => {
              if (value) {
                collectEvent?.([
                  {
                    event: ProofreadingEvent.CLICK_CHECK_ISSUE_TAB,
                    params: {
                      moduleName: ModuleName.AI_PROOFREADING,
                      category: info.item.issue?.category || '',
                    },
                  },
                ]);
              }
            }}
            value={info.item.issue!.category}
            className={classNames('flex', 'justify-center', 'items-center', {
              'btn-main': type === 'main',
              'btn-sub': type === 'sub',
              'active-main': mainCatalog?.includes(info.name || ''),
              'active-sub': info.name === subCatalog,
              'btn-common': info.item.issue?.category === PCCategory.COMMON_ERRORS,
              'btn-proper-names': info.item.issue?.category === PCCategory.PROPER_NAMES,
            })}
          >
            {matchName(locale, info.name)}&nbsp;<span className="count">{info.count}</span>
            {/* <IconClose
              className="btn-icon"
              onClick={e => {
                e.stopPropagation();
                onIgnore?.(type, info.name);
              }}
            /> */}
          </Checkbox>
        );
      });
  };

  const mainCatalogButton = useMemo(() => {
    console.log('mainCatalog======>', mainCatalog);
    const mainInfo: CatalogNum[] = [];
    items.forEach(item => {
      if (mainInfo.findIndex(info => info.name === item.issue?.category) < 0) {
        mainInfo.push({ name: item.issue?.category, count: 1, item });
      } else {
        mainInfo.forEach(info => {
          if (info.name === item.issue?.category) {
            info.count!++;
          }
        });
      }
    });
    return catalogButton(mainInfo, 'main');
  }, [items, mainCatalog, lang]);

  const subCatalogButton = useMemo(() => {
    if (!mainCatalog) {
      return null;
    }
    const subInfo: CatalogNum[] = [];
    items.forEach(item => {
      if (!mainCatalog.includes(item.issue?.category || '')) {
        return;
      }
      if (subInfo.findIndex(info => info.name === item.issue?.subcategory) < 0) {
        subInfo.push({ name: item.issue?.subcategory, count: 1, item });
      } else {
        subInfo.forEach(info => {
          if (info.name === item.issue?.subcategory) {
            info.count!++;
          }
        });
      }
    });

    if (subInfo.length <= 0) {
      return null;
    }

    return catalogButton(subInfo, 'sub');
  }, [items, mainCatalog, subCatalog, lang]);

  return (
    <>
      <div className="total-space flex justify-between items-center">
        <div className="total-space-label">
          {locale.proofreading_all_issues} {items.length}
        </div>
        <Link
          className="btn-total"
          onClick={e => {
            e.stopPropagation();
            onIgnore?.('total');
          }}
        >
          {locale.proofread_ignore_all}
        </Link>
      </div>
      <div className="main-catalog-main">
        <Checkbox.Group
          onChange={(values: string[]) => {
            onCatalogChange?.('main', values);
          }}
          value={mainCatalog}
          className="main-catalog-group"
        >
          {mainCatalogButton}
        </Checkbox.Group>
      </div>
      {/* {subCatalogButton && <Space className="sub-catalog-button">{subCatalogButton}</Space>} */}
    </>
  );
};

export default SummarizeTab;
