import { random } from 'lodash-es';

const aiBotGovFaqs = [
  'ai-bot-faqs-1',
  'ai-bot-faqs-2',
  'ai-bot-faqs-3',
  'ai-bot-faqs-4',
  'ai-bot-faqs-5',
  'ai-bot-faqs-6',
  'ai-bot-faqs-7',
  'ai-bot-faqs-8',
  'ai-bot-faqs-9',
  'ai-bot-faqs-10',
];

export const getRandomGovFaqs = (currentCase?: string[]) => {
  const newCase: string[] = [];
  while (newCase.length < 3) {
    const index = random(0, aiBotGovFaqs.length - 1);
    const csc = aiBotGovFaqs[index];
    if (!currentCase?.includes(csc) && !newCase.includes(csc)) {
      newCase.push(csc);
    }
  }
  return newCase;
};
