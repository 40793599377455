import './index.scss';

import { CSSProperties, FC, useContext, useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { Tag, Modal, Message, Tooltip } from '@arco-design/web-react';
import { IconAttachment, IconCloseCircleFill, IconQuote } from '@arco-iconbox/react-hkgai-government-icon';
import cls from 'classnames';
import { useModel } from '@modern-js/runtime/model';
import { v4 as uuidv4 } from 'uuid';
import GlobalStore from '@store/globalStore';
import AiBotShuffleCase from '../ai-bot-shuffle-case';
import AiChatMessage, { ChatMessage, Role } from '../ai-chat-message';
import ResourcesModal, { ActionType, Resource } from '../upload-modal';
import AiChatBotHead from './ai-chat-bot-head';
import AiChatBotFooter from './ai-chat-bot-footer';
import { abortAiBotGenerate, fetchAIbot } from '@/api/event-source_api';
import AiBotStore from '@/store/aiBotStore';
import { AIWorkStatus } from '@/type/ui';
import useLocale from '@/hooks/useLocale';
import { fetchDeleteConversation } from '@/api/copilot_api';
import { GlobalContext } from '@/routes/context';
import { AiBotEvent, ModuleName } from '@/config/track.config';

interface AiChatBotProps {
  visible?: boolean;
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
  className?: string | string[];
  onClose?: () => void;
}

const AiChatBot: FC<AiChatBotProps> = ({ className, visible = false, onClose, style, bodyStyle }) => {
  const locale = useLocale();
  const { collectEvent } = useContext(GlobalContext);

  const scrollRef = useRef<any>();
  const messageRef = useRef<any>();
  const timerRef = useRef<any>({});
  const inputRef = useRef<any>();
  const [loading, setLoading] = useState(false); // 对话loading状态， 用于头部动画切换
  const [resourcesModalVisible, setResourcesModalVisible] = useState(false);
  const [{ selectFiles = [], resources = [], fileList = [] }, { setAddResources, setSelectFiles }] = useModel(GlobalStore);
  const [
    { chatMessage = [], currentConversationId, showQuote, quoteRect, quoteText, replyQuote },
    {
      addChatMessage,
      setNewChatMessage,
      completeChatMessage,
      clearChatMessage,
      updateMessage,
      reRestChatMessage,
      resetCurrentConversationId,
      removeLastChatMessage,
      setShowQuote,
      setQuoteRect,
      setQuoteText,
      setReplyQuote,
    },
  ] = useModel(AiBotStore);
  //   const [summaryPrompt, setSummaryPrompt] = useState('');
  const [aIWorkStatus, setAIWorkStatus] = useState<AIWorkStatus>(AIWorkStatus.NOT_WORKING);
  const [messageVal, setMessageVal] = useState('');

  const deleteConversation = async () => {
    try {
      await fetchDeleteConversation({ key: 'PROMPT_CHAT_BOT', id: currentConversationId });
      clearChatMessage([]);
      setReplyQuote('');
      setSelectFiles([]);
      setMessageVal(() => '');
      resetCurrentConversationId();
      collectEvent?.([
        {
          event: AiBotEvent.CLICK_CLEAR_CONTEXT,
          params: {
            moduleName: ModuleName.AI_BOT,
            conversationId: currentConversationId,
          },
        },
      ]);
    } catch (error) {}
  };

  const clearContext = () => {
    if (!chatMessage.length || aIWorkStatus !== AIWorkStatus.NOT_WORKING) {
      return;
    }
    Modal.confirm({
      autoFocus: false,
      title: locale.ai_bot_confirm_modal_title,
      content: locale.ai_bot_confirm_modal_content,
      okText: locale.ai_bot_confirm_modal_button_confirm,
      okButtonProps: {
        status: 'danger',
      },
      onOk: deleteConversation,
    });
  };
  const onScrollToBottom = () => {
    if (scrollRef?.current?.scrollTop) {
      scrollRef.current.scrollTo({
        behavior: 'smooth',
        top: (messageRef.current.scrollHeight as number) + 130,
      });
    }
    scrollRef.current.scrollTop = Number(messageRef.current?.scrollHeight || 0);
  };

  const generateCallBack = (
    { value = '', conversationId = '', massageId = '', startTime = 0, firstTime = 0, endTime = 0, totalTokens = 0, totalSteps = 0 },
    status = AIWorkStatus.NOT_WORKING,
  ) => {
    const baseParams = {
      totalSteps: `${totalSteps}`,
      tokenCount: `${totalTokens}`,
      tokens: `${parseFloat((totalTokens / Math.ceil(Math.abs(endTime - firstTime) / 1000)).toFixed(2))}t/s`,
      duration: `${endTime - startTime}`,
      ttft: `${parseFloat((Math.abs(firstTime - startTime) / 1000).toFixed(2))};`,
      conversationId: currentConversationId,
      moduleName: ModuleName.AI_BOT,
    };

    setAIWorkStatus(() => status);
    setSelectFiles([]);
    // 生成失败
    if (status === AIWorkStatus.CHATError) {
      setAIWorkStatus(() => AIWorkStatus.NOT_WORKING);
      removeLastChatMessage();
      collectEvent?.([
        {
          event: AiBotEvent.CLICK_MESSAGE_SEND,
          params: {
            ...baseParams,
            status: 'fail',
          },
        },
      ]);
      return;
    }

    // 生成结束
    if (status === AIWorkStatus.COMPLETED) {
      setAIWorkStatus(() => AIWorkStatus.NOT_WORKING);
      completeChatMessage({ status: 'complete' });
      collectEvent?.([
        {
          event: AiBotEvent.CLICK_MESSAGE_SEND,
          params: {
            ...baseParams,
            status: 'success',
          },
        },
      ]);
      onScrollToBottom();
      requestAnimationFrame(() => {
        inputRef.current.onFocus();
      });
    }

    // 生成中....
    if (status === AIWorkStatus.WORKING) {
      setNewChatMessage({ conversationId, id: massageId, status: 'incomplete' });
    }

    // 更新生成内容
    updateMessage({ answer: value, conversationId });
    onScrollToBottom();
  };

  // 生成内容
  const onMessageSend = (content: string, quote: string) => {
    if (!content) {
      return;
    }

    // setSummaryPrompt(() => '');
    setAIWorkStatus(() => AIWorkStatus.WORK_PENDING);
    // resetCurrentConversationId();
    const messageId = uuidv4();
    addChatMessage([
      {
        id: messageId,
        query: content,
        role: Role.user,
        quote,
      },
      {
        id: messageId,
        answer: '',
        status: 'loading',
        role: Role.system,
        quote,
      },
    ]);
    scrollRef.current.scrollTop = Number(messageRef.current?.scrollHeight || 0);
    const params = {
      content: quote ? `<quotes>${quote}</quotes>\n${content}` : content,
      conversationId: currentConversationId,
      text: selectFiles.map(item => item.value).toString(),
    };
    fetchAIbot(params, generateCallBack);
  };

  //   停止生成
  const onStopGenerator = () => {
    collectEvent?.([
      {
        event: AiBotEvent.CLICK_STOP_GENERATOR,
        params: {
          conversationId: currentConversationId,
          moduleName: ModuleName.AI_BOT,
        },
      },
    ]);
    setAIWorkStatus(() => AIWorkStatus.NOT_WORKING);
    completeChatMessage({ status: 'complete' });
    abortAiBotGenerate();
  };

  //   重新生成
  const onMessageReset = (message: ChatMessage) => {
    const lastUserContext = chatMessage.find(chat => chat.id === message.id && chat.role === Role.user) || {};
    setAIWorkStatus(() => AIWorkStatus.WORK_PENDING);
    reRestChatMessage({ id: message.id });
    collectEvent?.([
      {
        event: AiBotEvent.CLICK_REGENERATE,
        params: {
          conversationId: message?.conversationId || '',
          content: lastUserContext?.quote ? `<quotes>${lastUserContext.quote}</quotes>\n${lastUserContext?.query}` : lastUserContext?.query || '',
          moduleName: ModuleName.AI_BOT,
        },
      },
    ]);
    const params = {
      conversationId: message?.conversationId,
      content: lastUserContext?.quote ? `<quotes>${lastUserContext.quote}</quotes>\n${lastUserContext?.query}` : lastUserContext?.query,
      text: selectFiles.map(item => item.value).toString(),
    };
    fetchAIbot(params, generateCallBack);
  };

  //  复制
  const handleMessageCopy = async (value: string) => {
    if (value) {
      await navigator.clipboard.writeText(value);
      Message.success(locale.ai_bot_copy_success_toast);
      collectEvent?.([
        {
          event: AiBotEvent.CLICK_MESSAGE_COPY,
          params: {
            content: value || '',
            moduleName: ModuleName.AI_BOT,
          },
        },
      ]);
    }
  };
  const handleRemoveSelectFile = (id: string) => {
    const old = [...selectFiles];
    setSelectFiles(old.filter(item => item.uuid !== id));
  };

  const handleShuffleCase = (value: string) => {
    onMessageSend(value, '');
    collectEvent?.([
      {
        event: AiBotEvent.CLICK_TOPICS,
        params: {
          content: value,
          moduleName: ModuleName.AI_BOT,
        },
      },
    ]);
  };
  const handleMessageSend = (value: string, quote: string) => {
    onMessageSend(value, quote);
    collectEvent?.([
      {
        event: AiBotEvent.CLICK_MESSAGE_SEND,
        params: {
          moduleName: ModuleName.AI_BOT,
        },
      },
    ]);
  };

  //   const handleSummary = () => {
  //     setSummaryPrompt(() => locale.ai_bot_summary_prompt);
  //   };

  const isNewConversation = useMemo(() => {
    return !chatMessage.length;
  }, [chatMessage.length]);

  const handleReplyAll = useCallback((text: string) => {
    setReplyQuote(text);
    requestAnimationFrame(() => {
      inputRef.current.onFocus();
    });
  }, []);

  const handleReply = useCallback(() => {
    setReplyQuote(quoteText);
    requestAnimationFrame(() => {
      inputRef.current.onFocus();
    });
  }, [quoteText]);

  const handleAibotSelection = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount < 1) {
      setShowQuote(false);
      return;
    }
    const range = selection?.getRangeAt(0);
    // console.log('----range', range, range?.toString(), range?.toString().length);
    // console.log('----range start node: ', range?.startContainer);
    // console.log('----range end node: ', range?.endContainer);
    // console.log('----range is same node: ', range?.startContainer === range?.endContainer);
    const rangeText = range?.toString();
    const rangeTextWithoutSpace = rangeText?.replace(/\s/g, '');
    const allowClassNameList = ['ai-chat-message-answer-type']; // 白名单

    const checkRangeContainer = (start: HTMLElement | null, end: HTMLElement | null) => {
      // 检查是不是answer内容， 并且起始终点需要是同一个dom容器
      const maxLimit = 10; // 暂定10层， 具体看markdown内容层级调整
      const allow = false;
      let times = 1;
      let curStartNode = start;
      let curEndNode = end;
      let targetStartNode = null;
      let targetEndNode = null;

      while (times < maxLimit) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < allowClassNameList.length; i++) {
          if (curStartNode?.className && curStartNode.className.indexOf(allowClassNameList[i]) > -1) {
            targetStartNode = curStartNode;
          }
          if (curEndNode?.className && curEndNode.className.indexOf(allowClassNameList[i]) > -1) {
            targetEndNode = curEndNode;
          }
          if (targetStartNode && targetEndNode) {
            break;
          }
        }
        if (targetStartNode && targetEndNode) {
          break;
        }
        if (!targetStartNode && curStartNode) {
          curStartNode = curStartNode.parentElement;
        }
        if (!targetEndNode && curEndNode) {
          curEndNode = curEndNode.parentElement;
        }
        times++;
      }
      // console.log('-----target:', targetStartNode === targetEndNode, targetStartNode, targetEndNode);
      return targetStartNode && targetEndNode && targetStartNode === targetEndNode;
    };
    if (
      range &&
      range.startOffset !== range.endOffset &&
      rangeTextWithoutSpace &&
      rangeTextWithoutSpace.length > 0 &&
      checkRangeContainer(range.startContainer.parentElement, range.endContainer.parentElement)
    ) {
      // show quote
      const rangeRect = range.getClientRects()[0]; // 展示在上方的话按第一行矩形算， 有其他case则对应取矩形
      const scrollRect = scrollRef.current.getBoundingClientRect();
      // console.log(rangeRect, range.getClientRects());
      // 拿到range的位置和chat滚动容器位置以及滚动距离， 确定quote相对滚动容器的位置, 简化下能定位就行
      const rect = {
        left: rangeRect.left - scrollRect.left,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        top: rangeRect.top - scrollRect.top + scrollRef.current.scrollTop,
      };
      setQuoteRect(rect);
      setShowQuote(true);
      setQuoteText(rangeText);
    } else {
      setShowQuote(false);
    }
  }, []);

  const throtHandleAibotSelection = useCallback(() => {
    if (timerRef.current.selectionTimer) {
      clearTimeout(timerRef.current.selectionTimer);
    }
    timerRef.current.selectionTimer = setTimeout(handleAibotSelection, 150);
  }, []);

  useEffect(() => {
    document.addEventListener('selectionchange', throtHandleAibotSelection);

    return () => {
      document.removeEventListener('selectionchange', throtHandleAibotSelection);
    };
  }, []);

  if (!visible) {
    return <></>;
  }

  return (
    <>
      <div className={cls('ai-chat-bot', className, { 'transition-entered': visible, 'transition-exited': !visible })} style={style} onClick={e => e.stopPropagation()}>
        <div className="ai-chat-bot__head">
          <AiChatBotHead
            onClear={clearContext}
            onClose={onClose}
            title={locale.ai_bot_head_title}
            clearButtonText={locale.ai_bot_head_clear_button}
            tooltipContent={locale.ai_bot_tips_close_button}
            disabled={chatMessage.length > 0}
            loading={aIWorkStatus !== AIWorkStatus.NOT_WORKING}
            isNewConversation={isNewConversation}
          />
        </div>
        <div className="ai-chat-bot__body" style={bodyStyle}>
          <div className="chat-message chat-message-scroll-hidden" ref={scrollRef}>
            {isNewConversation ? (
              <AiBotShuffleCase
                onMessageSend={handleShuffleCase}
                title={locale.ai_bot_slogan}
                onRefresh={() => {
                  collectEvent?.([
                    {
                      event: AiBotEvent.CLICK_REFRESH,
                      params: {
                        moduleName: ModuleName.AI_BOT,
                      },
                    },
                  ]);
                }}
              />
            ) : (
              <div ref={messageRef}>
                <AiChatMessage chatMessage={chatMessage} onMessageReset={onMessageReset} onMessageCopy={handleMessageCopy} onQuoteAll={handleReplyAll} />
                <div className="h-[130px]"></div>
              </div>
            )}
            {showQuote ? (
              <Tooltip content={locale.ai_bot_action_quote} position="top">
                <div
                  className="ai-chat-bot__quote"
                  style={{
                    left: `${quoteRect.left}px`,
                    top: `${quoteRect.top - 40}px`,
                  }}
                  onMouseDown={() => {
                    handleReply();
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleReply();
                  }}
                >
                  <IconQuote
                    style={{
                      fontSize: '20px',
                      color: '#4E5969',
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="ai-chat-bot__footer">
          <AiChatBotFooter
            ref={inputRef}
            placeholder={locale.ai_bot_footer_input_placeholder}
            inputValue={messageVal}
            replyQuote={replyQuote}
            showStopGenerate={aIWorkStatus !== AIWorkStatus.NOT_WORKING}
            onMessageSend={handleMessageSend}
            onClearQuote={() => setReplyQuote('')}
            onStopGenerator={onStopGenerator}
            onUpload={() => setResourcesModalVisible(() => true)}
            onInputChange={value => setMessageVal(value)}
            // topSlot={
            //   <div className="actions-content">
            //     {/* <Button className="actions-button" icon={<IconCode />}>
            //       大纲总结
            //     </Button> */}
            //     <Button className="actions-button" icon={<IconCode />} onClick={handleSummary}>
            //       {locale.ai_bot_action_summary}
            //     </Button>
            //   </div>
            // }
            bottomSlot={
              <div className="links-content" style={{ paddingTop: selectFiles.length ? '16px' : 0 }}>
                <div className="link-list">
                  {selectFiles.map(item => (
                    <Tag
                      className="link-tag"
                      key={item.uuid}
                      icon={<IconAttachment />}
                      closeIcon={<IconCloseCircleFill />}
                      closable
                      onClose={() => handleRemoveSelectFile(item.uuid || '')}
                    >
                      {item.name}
                    </Tag>
                  ))}
                </div>
              </div>
            }
          />
        </div>
      </div>
      <ResourcesModal
        modalProps={{
          visible: resourcesModalVisible,
          onCancel: () => {
            setResourcesModalVisible(() => false);
          },
        }}
        extraMessage={locale.upload_modal_tab_limit_text}
        tabs={[ActionType.UPLOAD_ATTACHMENT]}
        resources={fileList}
        defaultValue={selectFiles.map(item => ({ id: item.uuid, name: item.name, type: item.type })) || []}
        onConfirm={(values: Resource[]) => {
          setSelectFiles(values);
          setResourcesModalVisible(() => false);
        }}
        onUpload={(file: Resource[]) => {
          console.log('add file', file);
          setAddResources([...resources, ...file]);
        }}
        onChange={(files: Resource[]) => {
          setAddResources(files);
        }}
      />
    </>
  );
};
export default AiChatBot;
export type { AiChatBotProps };
