import './index.scss';

import {
  IconCircleNotch,
  IconCopy,
  IconTherefresh,
  IconWarningCircleRed,
  IconAiBotQuote,
  IconThumbsDown,
  IconThumbsDownSelected,
  IconCloseButton,
  IconQuote,
} from '@arco-iconbox/react-hkgai-government-icon';
import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import { Tooltip, Input, Button } from '@arco-design/web-react';
import ReactMarkdown from 'react-markdown';
import cls from 'classnames';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coyWithoutShadows, oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DotsLoader from '../dots-loader';
import useLocale from '@/hooks/useLocale';
import { chatResultReport } from '@/api/copilot_api';

const them = {
  dark: oneDark,
  light: coyWithoutShadows,
};
enum Role {
  system,
  user,
}

const feedbackTagKeyList = ['ai_bot_feedback_tag_1', 'ai_bot_feedback_tag_2', 'ai_bot_feedback_tag_3', 'ai_bot_feedback_tag_4'];

interface ChatMessage {
  id?: string; // 对话ID
  conversationId?: string; // 对话标识符
  inputs?: [{ key: string }]; // 输入参数
  query?: string; // 用户查询
  quote?: string; // 引用内容
  answer?: string; // 对话回答
  status?: 'loading' | 'incomplete' | 'complete' | 'error';
  role?: Role;
  createdAt?: number; // 创建时间（Unix时间戳）
}

// status 消息状态，可选值为 loading | incomplete | complete | error
interface AiChatMessageProps {
  chatMessage?: ChatMessage[];
  style?: CSSProperties;
  //   hints?: ReactNode;
  //   //   onHintClick
  className?: string | string[];
  onMessageCopy?: (value: string) => void;
  onQuoteAll?: (value: string) => void;
  onMessageReset?: (message: ChatMessage) => void;
  onFeedback?: () => void;
}

const AiChatMessage: FC<AiChatMessageProps> = ({ style, className, chatMessage, onMessageReset, onMessageCopy, onQuoteAll, onFeedback }) => {
  const locale = useLocale();

  let timeoutId: NodeJS.Timeout;

  //   const loaderDots = `<span class="loader-dots"></span>`;

  const [loading, setLoading] = useState(false);

  const [showFeedback, setShowFeedback] = useState(false);

  const [feedbackInputText, setFeedbackInputText] = useState('');

  const [curFeedbackId, setCurFeedbackId] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);

  const [showSubmitSucc, setShowSubmitSucc] = useState(false);

  const [feedbackTags, setFeedbackTags] = useState<string[]>([]);

  const [feedbackIds, setFeedbackIds] = useState<string[]>([]); // 进行过dislike

  const handleRepeat = (chat: ChatMessage) => {
    onMessageReset?.(chat);
    setLoading(() => true);
    timeoutId = setTimeout(() => {
      setLoading(() => false);
    }, 500);
  };

  const toggleFeedbackPannel = useCallback(
    (id: string) => {
      const index = feedbackIds.indexOf(id);
      if (index > -1) {
        // 先不处理已feedback的点击事件， 需要取消dislike再加
        return;
      }
      if (curFeedbackId === id) {
        setFeedbackInputText('');
        setFeedbackTags([]);
        setCurFeedbackId(id);
        setShowFeedback(val => !val);
      } else {
        setFeedbackInputText('');
        setFeedbackTags([]);
        setCurFeedbackId(id);
        setShowFeedback(true);
        setShowSubmitSucc(false);
      }
    },
    [showFeedback, curFeedbackId, feedbackIds],
  );

  const handleTagClick = useCallback(
    (tag: string) => {
      const index = feedbackTags.indexOf(tag);
      if (index > -1) {
        const newTags = feedbackTags.slice(0);
        newTags.splice(index, 1);
        setFeedbackTags(newTags);
      } else {
        const newTags = feedbackTags.slice(0);
        newTags.push(tag);
        setFeedbackTags(newTags);
      }
    },
    [feedbackTags],
  );

  const submitFeedback = useCallback(
    async (chat: ChatMessage) => {
      const params = {
        comments: [feedbackInputText],
        generate_id: chat.conversationId || '',
        key: 'PROMPT_CHAT_BOT',
        model: '',
        parameters: [
          {
            key: 'feedbackTags',
            value: JSON.stringify(feedbackTags),
          },
        ],
        result: chat.answer || '',
        stream: true,
      };
      setSubmitLoading(true);
      await chatResultReport(params);
      setSubmitLoading(false);
      toggleFeedbackPannel(chat.id || '');
      setFeedbackIds(ids => {
        return [...ids, chat.id || ''];
      });
      setShowSubmitSucc(true);
    },
    [feedbackTags, feedbackInputText],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={cls('ai-chat-message', className)} style={style}>
      {chatMessage?.map((chat, index) => (
        <div className="ai-chat-message-list" key={`${chat.id}-${chat.role}`} id={`${chat.id}-${chat.role}`}>
          {chat.query && chat.role === Role.user && chat.quote ? (
            <div className="ai-chat-message-quote-wrap">
              <IconAiBotQuote
                style={{
                  fontSize: '18px',
                  color: '#4E5969',
                  flexShrink: 0,
                  marginRight: '8px',
                }}
              />
              <div className="quote-text">{chat.quote}</div>
            </div>
          ) : null}
          <div className={cls('ai-chat-message-content', { 'ai-chat-message-content-reverse': chat.role === Role.user })}>
            {chat.role === Role.system && (
              <div className="ai-chat-message-wrap">
                <div className="ai-chat-message-markdown-render ai-chat-message-answer-type">
                  {chat.status === 'loading' ? (
                    <DotsLoader />
                  ) : (
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ node, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '');
                          return match ? (
                            <SyntaxHighlighter showLineNumbers={true} wrapLongLines={true} style={them.dark} language={match[1]} PreTag="div">
                              {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                          ) : (
                            <code {...props}>{children}</code>
                          );
                        },
                      }}
                    >
                      {chat?.answer}
                    </ReactMarkdown>
                  )}
                </div>
                {chat.status === 'complete' && chat.answer && (
                  <div className={cls('ai-chat-message-action ai-chat-message-action-show')}>
                    {index === chatMessage.length - 1 && (
                      <Tooltip content={locale.ai_bot_action_button_restart}>
                        <div className="icon-box" onClick={() => onMessageReset?.(chat)}>
                          <IconTherefresh />
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip content={locale.ai_bot_action_button_copy}>
                      <div className="icon-box" onClick={() => onMessageCopy?.(chat.answer || '')}>
                        <IconCopy />
                      </div>
                    </Tooltip>
                    <Tooltip content={locale.ai_bot_action_quote_all} position="top">
                      <div className="icon-box" onClick={() => onQuoteAll?.(chat.answer || '')}>
                        <IconQuote
                          style={{
                            fontSize: '20px',
                            color: '#4E5969',
                          }}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip content={locale.ai_bot_action_button_feedback}>
                      <div className="icon-box" onClick={() => toggleFeedbackPannel(chat.id || '')}>
                        {feedbackIds.indexOf(chat.id || '') > -1 || (showFeedback && curFeedbackId === chat.id) ? (
                          <IconThumbsDownSelected color="#4E5969" />
                        ) : (
                          <IconThumbsDown color="#4E5969" />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
            {chat.query && chat.role === Role.user && (
              <div className="ai-chat-message-wrap ai-chat-message-wrap-reverse">
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  {chat.status === 'error' ? (
                    <div className="action-list">
                      {/* <Tooltip content={locale.ai_bot_action_button_repeat}>
                        <div className={cls('icon-content', { 'icon-rotate': loading })} onClick={() => handleRepeat(chat)}>
                          <IconCircleNotch />
                        </div>
                      </Tooltip>
                      <IconWarningCircleRed /> */}
                      {loading ? (
                        <div className={cls('icon-content', { 'icon-rotate': loading })}>
                          <IconCircleNotch />
                        </div>
                      ) : (
                        <Tooltip content={locale.ai_bot_action_button_repeat}>
                          <IconWarningCircleRed onClick={() => handleRepeat(chat)} />
                        </Tooltip>
                      )}
                    </div>
                  ) : null}
                  {chat.status === 'complete' ? (
                    <div className="action-copy-wrap">
                      <Tooltip content={locale.ai_bot_action_button_copy} position="bottom">
                        <div className="icon-box" onClick={() => onMessageCopy?.(chat.query || '')}>
                          <IconCopy />
                        </div>
                      </Tooltip>
                    </div>
                  ) : null}
                  <div className="ai-chat-message-markdown-render">{chat.query}</div>
                </div>
                <div
                  className="ai-chat-message-action"
                  style={{
                    paddingTop: '2px',
                  }}
                ></div>
              </div>
            )}
          </div>
          {chat.role === Role.system && showFeedback && curFeedbackId === chat.id ? (
            <div className="ai-chat-message-feedback-wrap">
              <div className="header">
                <div className="title">{locale.ai_bot_feedback_title}</div>
                <IconCloseButton
                  onClick={() => toggleFeedbackPannel(chat.id || '')}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className="tags">
                {feedbackTagKeyList.map(tag => (
                  <div
                    className="tag"
                    key={locale[tag]}
                    onClick={() => handleTagClick(locale[tag])}
                    style={
                      feedbackTags.indexOf(locale[tag]) > -1
                        ? {
                            background: '#165DFF',
                            borderColor: '#165DFF',
                            color: '#FFFFFF',
                          }
                        : {}
                    }
                  >
                    {locale[tag]}
                  </div>
                ))}
              </div>
              <div className="footer">
                <Input className="feedback-input" placeholder={locale.ai_bot_feedback_placeholder} value={feedbackInputText} onChange={val => setFeedbackInputText(val)} />
                <Button
                  className="feedback-submit"
                  type="primary"
                  onClick={() => submitFeedback(chat)}
                  loading={submitLoading}
                  disabled={feedbackTags.length < 1 && feedbackInputText.length < 1}
                >
                  {locale.ai_bot_action_feedback_submit}
                </Button>
              </div>
            </div>
          ) : null}
          {chat.role === Role.system && !showFeedback && showSubmitSucc && curFeedbackId === chat.id ? (
            <div className="ai-chat-message-feedback-succ-wrap">
              <div className="title">{locale.ai_bot_feedback_succ_msg}</div>
              <IconCloseButton
                onClick={() => setShowSubmitSucc(false)}
                style={{
                  flexGrow: 0,
                  flexShrink: 0,
                  cursor: 'pointer',
                  color: '#4E5969',
                  marginLeft: '36px',
                }}
              />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default AiChatMessage;
export { AiChatMessageProps, Role, ChatMessage };
