import { Descendant } from 'slate';
import { PROOFREAD_LINE_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import { EditorKit } from '@hkgai/slate-plugin/dist/lib/core';
import { Element } from '@hkgai/slate-plugin/dist/lib/delta';
import { CheckParagraph, PCCategory, PCMatchParagraph, PCSubcategoryCommon, PCSubcategoryProperNoun } from '@/type/proofread';

// 前端手动排序
export const sortMatchItems = (matchItems: PCMatchParagraph[]) => {
  return matchItems.sort((a, b) => {
    // 前端兜底排序
    if (a.paragraph === b.paragraph) {
      return (a.offset || 0) - (b.offset || 0);
    }
    return a.paragraph - b.paragraph;
  });
};

// 获取text
export const getAllTextUsingDFS = (editor: EditorKit) => {
  const textContent: any = [];
  // 递归函数用于深度优先遍历节点并提取文本内容
  const dfsTraversal = (parent: any, node: any, path: number[]) => {
    if (editor.reflex.isText(node)) {
      textContent.push({ text: node.text, path, parent: { node: parent, path: path.slice(0, 1) } });
    } else if (editor.raw.isBlock(node)) {
      // 对于块节点，遍历其所有子节点
      if (node.children) {
        node.children.forEach((child: any, index: number) => dfsTraversal(node.children, child, [...path, index]));
      }
    } else if (Element.isElement(node)) {
      // 对于元素节点，同样遍历其所有子节点
      if (node.children) {
        node.children.forEach((child: any, index: number) => dfsTraversal(node.children, child, [...path, index]));
      }
    }
  };

  const { children } = editor.raw;
  children.forEach((child, index) => {
    dfsTraversal(children, child, [index]);
  });
  return textContent;
};

export const calculateLocation = (editor: EditorKit, matchItem?: PCMatchParagraph) => {
  // const { children = [] } = editor.raw;
  const newTextContent = getAllTextUsingDFS(editor).reduce((acc: any, item: any) => {
    const itemParent = JSON.stringify(item?.parent);
    const accLastItem = acc[acc.length - 1];
    const accParent = JSON.stringify(accLastItem?.[accLastItem.length - 1].parent);
    if (itemParent === accParent) {
      acc[acc.length - 1].push(item);
    } else {
      acc.push([item]);
    }
    return acc;
  }, []);
  const { paragraph = 0, offset = 0, length = 0 } = matchItem || {};
  const matchBlock = newTextContent[paragraph];
  let anchorIndex2 = 0;
  let focusIndex2 = 0;
  let anchorOffset2 = 0;
  let focusOffset2 = 0;
  matchBlock.reduce((acc: number, item: any, index: number) => {
    if (offset >= acc && offset <= acc + (item.text.length as number)) {
      anchorIndex2 = index;
      anchorOffset2 = offset - acc;
    }
    if (offset + length >= acc && offset + length <= acc + (item.text.length as number)) {
      focusIndex2 = index;
      focusOffset2 = offset + length - acc;
    }
    return acc + (item.text.length as number);
  }, 0);

  return {
    anchor: { path: matchBlock[anchorIndex2].path, offset: anchorOffset2 },
    focus: { path: matchBlock[focusIndex2].path, offset: focusOffset2 },
  };

  // const anchorPath0 = matchItem?.paragraph || 0;
  // const focusPath0 = matchItem?.paragraph || 0;
  // let anchorPath1 = 0;
  // let focusPath1 = 0;
  // let anchorOffset = 0;
  // let focusOffset = 0;

  // let strLen = 0; // 文本总长度

  // const arr = children[anchorPath0].children || [];

  // for (let index = 0, len = arr.length; index < len; index++) {
  //   const element = arr[index];
  //   const l = element.text?.length || 0; // children里面，当前循环下，文本的长度
  //   const offset = matchItem?.offset || 0; // 校对结果中，在当前段落中的总偏移量
  //   const length = matchItem?.length || 0; // 校对的文本的长度
  //   if (offset >= strLen && offset < strLen + l) {
  //     // offset > strLen的时候，说明校对的文本在这个item里面或后面，&& offset <= strLen + l，校对的文本的起始点落在这个item里面
  //     anchorPath1 = index;
  //     // 在这个block里面strLen代表这个item前面文本的总长度
  //     anchorOffset = offset - strLen;
  //   }
  //   if (offset + length > strLen && offset + length <= strLen + l) {
  //     // 判断校对的文本的终点落在这个item里面
  //     focusPath1 = index;
  //     focusOffset = anchorOffset + length;
  //   }
  //   strLen += l;
  // }

  // return {
  //   anchor: { path: [anchorPath0, anchorPath1], offset: anchorOffset },
  //   focus: { path: [focusPath0, focusPath1], offset: focusOffset },
  // };
};

export const calculateLocationId = (children: Descendant[] = [], id = '') => {
  let anchorPath0 = 0;
  let anchorPath1 = 0;
  let currTextLength = 0;
  // const anchorOffset = 0;
  // const focusOffset = 0;

  try {
    children.forEach((item, index) => {
      item.children?.forEach((i, j) => {
        // @ts-expect-error
        const tid = i?.[PROOFREAD_LINE_KEY]?.id;
        if (tid === id) {
          anchorPath0 = index;
          anchorPath1 = j;
          currTextLength = i.text?.length || 0;
          throw new Error('break foreach');
        }
      });
    });
  } catch (error) {}

  return {
    anchor: { path: [anchorPath0, anchorPath1], offset: 0 },
    focus: { path: [anchorPath0, anchorPath1], offset: currTextLength },
  };
};

export const matchName = (locale: any, name?: string) => {
  switch (name) {
    case PCCategory.COMMON_ERRORS:
      return locale.proofreading_common_errors;
    case PCCategory.PROPER_NAMES:
      return locale.proofreading_proper_names;
    case PCSubcategoryCommon.EVENTS:
      return locale.proofreading_events;
    case PCSubcategoryCommon.ORGANIZATIONS:
      return locale.proofreading_organizations;
    case PCSubcategoryCommon.PERSONS:
      return locale.proofreading_persons;
    case PCSubcategoryProperNoun.PUNCTUATION:
      return locale.proofreading_punctuation;
    case PCSubcategoryProperNoun.TYPO:
      return locale.proofreading_typo;
    case PCSubcategoryProperNoun.GRAMMAR:
      return locale.proofreading_grammar;
    default:
      return '';
  }
};
