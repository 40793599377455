import '../index.scss';
import { FC } from 'react';
import { Button, Typography } from '@arco-design/web-react';
import classNames from 'classnames';
import { matchName } from '../utils';
import useLocale from '@/hooks/useLocale';
import { PCCategory, PCSubcategoryCommon } from '@/type/proofread';

const ProofreadDropList: FC<{
  originalText: string;
  targetText?: string;
  questionType?: string;
  onDeal: (type: 'accept' | 'dismiss') => void;
  category?: string;
  subcategory?: string;
}> = ({ originalText, targetText, questionType, onDeal, category = PCCategory.PROPER_NAMES, subcategory = PCSubcategoryCommon.ORGANIZATIONS }) => {
  const locale = useLocale();
  return (
    <div className="proofreadDdlWrapper">
      <div
        className={classNames('proofreadDdlTitle', {
          proofreadDdlTitleCommon: category === PCCategory.COMMON_ERRORS,
          proofreadDdlTitleProper: category === PCCategory.PROPER_NAMES,
        })}
      >
        <Typography.Ellipsis showTooltip className={classNames('originalBox')}>
          {originalText}
        </Typography.Ellipsis>
        <div className="proofreadDdlTitleSub">{matchName(locale, subcategory)}</div>
      </div>
      {category === PCCategory.COMMON_ERRORS && (
        <div className="targetBox">
          <div className="targetBoxLabel">{locale.proofread_dropdown_list_suggest_replace}</div>
          <Typography.Ellipsis showTooltip className="targetBoxContent">
            {targetText}
          </Typography.Ellipsis>
        </div>
      )}
      <div className="operation">
        <div className="operation_lable">
          {locale.proofread_dropdown_list_issue_description}
          {questionType}
        </div>
        <div className="operation_btn_group">
          {category === PCCategory.COMMON_ERRORS && (
            <Button
              type="primary"
              className="accept"
              onClick={() => {
                onDeal('accept');
              }}
            >
              {locale.proofreading_accept}
            </Button>
          )}
          <Button
            type="text"
            className="dismiss"
            onClick={() => {
              onDeal('dismiss');
            }}
          >
            {locale.proofread_dropdown_list_button_ignore}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProofreadDropList;
