import './index.scss';

const DotsLoader = () => {
  return (
    <div className="loader-wrap">
      <div className="loader"></div>
    </div>
  );
};

export default DotsLoader;
