import './index.scss';

import { CSSProperties, FC, ReactNode } from 'react';
import { Button } from '@arco-design/web-react';
import { IconRight } from '@arco-design/web-react/icon';
import classNames from 'classnames';

interface SidebarDrawerProps {
  closable?: boolean;
  visible?: boolean;
  closeIcon?: ReactNode;
  title?: string | ReactNode;
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
  className?: string | string[];
  //   onCancel?: () => void;
  onClose?: () => void;
  //   onOk?: () => void;
  children?: React.ReactNode;
}

const SidebarDrawer: FC<SidebarDrawerProps> = ({ visible = false, children, closable = true, closeIcon, title = null, bodyStyle, className, style, onClose }) => {
  return (
    <div className={classNames('sidebar-drawer', className, { 'transition-entered': visible, 'transition-exited': !visible })} style={style}>
      {title && <div className="sidebar-drawer-title">{title}</div>}
      <div className="sidebar-drawer-body" style={bodyStyle}>
        {children}
      </div>

      {closable && (
        <div className="close-button" onClick={onClose}>
          <Button icon={closeIcon ? closeIcon : <IconRight />} shape="circle" type="secondary" />
        </div>
      )}
    </div>
  );
};

export default SidebarDrawer;
export type { SidebarDrawerProps };
