import './index.scss';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { Collapse, Button, Link, Message, Typography } from '@arco-design/web-react';
import { IconLoading, IconRefresh } from '@arco-design/web-react/icon';
import { IconProofreadingEmptyState, IconProofreadingStartState } from '@arco-iconbox/react-hkgai-government-icon';
import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { PROOFREAD_LINE_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import { Editor, Transforms, Node, HistoryEditor } from '@hkgai/slate-plugin/dist/lib/delta';
import type { TextElement } from '@hkgai/slate-plugin/dist/lib/delta';
import { useDebounceEffect, useFocusWithin, useThrottleFn } from 'ahooks';
import dayjs from 'dayjs';
import SummarizeTab from './components/summarize-tab';
import ProofreadDropList from './components/proofread-ddl';
import { calculateLocation, getAllTextUsingDFS, matchName, sortMatchItems } from './utils';
import { CheckParagraph, PCCategory, PCMatchParagraph, ProofreadingLanguageType } from '@/type/proofread';
import EditorStore from '@/store/editorStore';
import useLocale from '@/hooks/useLocale';
import { fetchProofreadingCheck } from '@/api/copilot_api';
import { GlobalContext } from '@/routes/context';
import { useWritingContext } from '@/context/writingContext';
import { ModuleName, ProofreadingEvent } from '@/config/track.config';

const CheckItem: FC<{
  item: PCMatchParagraph;
  language: ProofreadingLanguageType;
  locale: any;
  onIgnore: (id: string, clickSource: string, subcategory?: string) => void;
  onReplace: (id: string, text: string, clickSource: string, subcategory?: string) => void;
  id: string;
}> = ({ item, language, locale, onIgnore, onReplace, id }) => {
  return (
    <>
      <Collapse.Item
        header={
          <div
            className={classNames('proofreading-collapse-item-header flex justify-between items-center', {
              'collapse-common-errors': item.issue?.category === PCCategory.COMMON_ERRORS,
              'collapse-proper-names': item.issue?.category === PCCategory.PROPER_NAMES,
            })}
          >
            <div className={'proofreading-collapse-item-header-title'}>
              <Typography.Ellipsis showTooltip className={classNames('proofreading-collapse-item-ellipsis')}>
                {item.text}
              </Typography.Ellipsis>
              <div className="proofreading-collapse-item-header-sub">{matchName(locale, item.issue?.subcategory)}</div>
            </div>
            <div className="proofreading-collapse-item-extra">{locale.proofreading_view}</div>
          </div>
        }
        name={id}
        className={classNames('proofreading-collapse-item')}
        expandIcon={''}
      >
        {item.issue?.category === PCCategory.PROPER_NAMES ? (
          <div className="proofreading-collapse-proper-names flex justify-between items-start">
            <div className="proofreading-collapse-proper-left">{item.issue?.description?.[language]}</div>
            <Link className="proofreading-collapse-proper-right" onClick={() => onIgnore(id, 'drawer', item.issue?.subcategory)}>
              {locale.proofread_dropdown_list_button_ignore}
            </Link>
          </div>
        ) : (
          <>
            <div className="proofreading-collapse-suggestion">
              <div className="proofreading-collapse-suggestion-label whitespace-nowrap">{locale.proofread_dropdown_list_suggest_replace}</div>
              <Typography.Ellipsis showTooltip className="proofreading-collapse-suggestion-content">
                {item?.replacements?.[0]?.value || ''}
              </Typography.Ellipsis>
            </div>
            <div className="proofreading-collapse-description">
              <div className="proofreading-collapse-description-label whitespace-nowrap">{locale.proofread_dropdown_list_issue_description}</div>
              <Typography.Ellipsis showTooltip className="proofreading-collapse-description-content">
                {item.issue?.description?.[language]}
              </Typography.Ellipsis>
              <div className="proofreading-collapse-description-action whitespace-nowrap">
                <Button className="action-btn" type="primary" size="mini" onClick={() => onReplace(id, item?.replacements?.[0]?.value || '', 'drawer', item.issue?.subcategory)}>
                  {locale.proofreading_accept}
                </Button>
                <Link className="action-link" onClick={() => onIgnore(id, 'drawer', item.issue?.subcategory)}>
                  {locale.proofread_dropdown_list_button_ignore}
                </Link>
              </div>
            </div>
          </>
        )}
      </Collapse.Item>
    </>
  );
};

const ProofreadingDrawer: FC = () => {
  const locale = useLocale();
  const { editorKit, editor, editableRef } = useWritingContext();
  const { lang = 'en-US', collectEvent } = useContext(GlobalContext);
  const [{ editorContent }] = useModel(EditorStore);

  const [matchItems, setMatchItems] = useState<PCMatchParagraph[]>([]);
  const [mainCatalog, setMainCatalog] = useState<string[]>([]);
  const [subCatalog, setSubCatalog] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [isProofread, setIsProofread] = useState<boolean>(false);
  const [isProofreading, setIsProofreading] = useState<boolean>(false);
  const [hasProofreaded, setHasProofreaded] = useState<boolean>(false);

  const timeRef = useRef(dayjs().unix());
  const currentTextRef = useRef<any>();
  const previousTextRef = useRef<any>();

  const langVal = useMemo(() => {
    switch (lang) {
      case 'zh-CN':
        return ProofreadingLanguageType.ZH_CHS;
      case 'zh-HK':
        return ProofreadingLanguageType.ZH_CHT;
      default:
        return ProofreadingLanguageType.EN;
    }
  }, [lang]);

  const fetchProofreading = async () => {
    const texts = editorContent?.plain?.split('\n').map((text, index) => ({ content: text, index }));
    if (!texts || !editorContent?.plain) {
      return;
    }
    clearAllStyle();
    setIsProofreading(() => true);

    setActiveKey(undefined);
    setMatchItems([]);
    const result = await fetchProofreadingCheck({ text: texts, enabledOnly: false });
    const { results = [] } = result;
    setHasProofreaded(true);
    Message.success(locale.proofreading_success);
    const tempMainCatalog: string[] = [];
    const tmpMatchItem: PCMatchParagraph[] = results
      ? results.reduce((acc: PCMatchParagraph[], item) => {
          const newMatches =
            item.matches?.map(match => {
              if (match.issue?.category && !tempMainCatalog.includes(match.issue.category)) {
                tempMainCatalog.push(match.issue.category);
              }
              const start = match.offset || 0;
              const len = start + (match.length || 0);
              return {
                ...match,
                paragraph: item.index || 0,
                text: texts[item.index || 0]?.content.substring(start, len),
                id: uuidv4(),
              };
            }) || [];
          return acc.concat(newMatches as PCMatchParagraph[]);
        }, [])
      : [];

    setIsProofreading(() => false);
    setMatchItems(() => sortMatchItems(tmpMatchItem));

    setMainCatalog(() => {
      if (tempMainCatalog.includes(PCCategory.COMMON_ERRORS)) {
        return [PCCategory.COMMON_ERRORS];
      }
      return [PCCategory.PROPER_NAMES];
    });
  };

  const { run: runFetchProofreading } = useThrottleFn(fetchProofreading, { wait: 1000 });

  // 点击忽略
  const onIgnore = (id: string, clickSource: string, subcatalog = '') => {
    setIsProofread(() => true);
    try {
      HistoryEditor.withoutSaving(editor!, () => {
        Transforms.unsetNodes(editor!, PROOFREAD_LINE_KEY, {
          at: [],
          match: node => {
            if (Object.prototype.hasOwnProperty.call(node, PROOFREAD_LINE_KEY) && (node as any)[PROOFREAD_LINE_KEY].id === id) {
              return true;
            }
            return false;
          },
        });
      });
    } catch (error) {}

    collectEvent?.([
      {
        event: ProofreadingEvent.CLICK_DISMISS,
        params: {
          moduleName: ModuleName.AI_PROOFREADING,
          clickSource,
          subcatalog,
        },
      },
    ]);

    setActiveKey(undefined);
    setMatchItems(temp => {
      return temp.filter(item => item.id !== id);
    });
  };

  // 点击采纳
  const onReplace = (iid: string, text: string, clickSource: string, subcatalog = '') => {
    setIsProofread(() => true);
    try {
      HistoryEditor.withoutSaving(editor!, () => {
        const marks = Editor.marks(editorKit!.raw) || {};
        marks[PROOFREAD_LINE_KEY] = {
          id: iid,
          action: 'accept',
          replaceTxt: text,
        };

        editorKit!.command.exec(PROOFREAD_LINE_KEY, { marks: marks as TextElement });
      });
    } catch (error) {}

    collectEvent?.([
      {
        event: ProofreadingEvent.CLICK_DISMISS,
        params: {
          moduleName: ModuleName.AI_PROOFREADING,
          clickSource,
          subcatalog,
        },
      },
    ]);

    setActiveKey(undefined);
    setMatchItems(temp => {
      return temp.filter(item => item.id !== iid);
    });
  };

  const checkList = useMemo(() => {
    return matchItems
      .filter(item => {
        if (!mainCatalog || mainCatalog.length === 0) {
          return false;
        }

        if (mainCatalog.includes(item.issue?.category || '')) {
          return true;
        }

        // if (subCatalog && item.issue?.subcategory === subCatalog) {
        //   return true;
        // }
        return false;
      })
      .map(item => <CheckItem item={item} key={item.id} id={item.id} language={langVal} locale={locale} onIgnore={onIgnore} onReplace={onReplace} />);
  }, [matchItems, mainCatalog, subCatalog, langVal]);

  const proofreadConfig = (matchItem: PCMatchParagraph) => {
    const colorSetting = () => {
      switch (matchItem.issue?.category) {
        case PCCategory.COMMON_ERRORS:
          return {
            underlineColor: '#165dff',
            backgroundColor: 'rgba(22, 93, 255, 0.1)',
          };
        case PCCategory.PROPER_NAMES:
          return {
            underlineColor: '#ff7d00',
            backgroundColor: 'rgba(255, 125, 0, 0.1)',
          };
        default:
          return {};
      }
    };

    return {
      ...colorSetting(),
      id: matchItem.id,
      action: 'mount',
      dropListNode: (
        <ProofreadDropList
          originalText={matchItem.text}
          targetText={matchItem.replacements?.[0]?.value}
          questionType={matchItem.issue?.description?.[langVal]}
          category={matchItem.issue?.category}
          subcategory={matchItem.issue?.subcategory}
          onDeal={(type: string) => {
            if (type === 'dismiss') {
              onIgnore(matchItem.id, 'content', matchItem.issue?.subcategory);
            }
            if (type === 'accept') {
              onReplace(matchItem.id, matchItem.replacements?.[0]?.value || '', 'content', matchItem.issue?.subcategory);
            }
          }}
        />
      ),
    };
  };

  useEffect(() => {
    if (isProofread) {
      setIsProofread(() => false);
      return;
    }
    try {
      matchItems.forEach(item => {
        const location = calculateLocation(editorKit!, item);
        HistoryEditor.withoutSaving(editor!, () => {
          const marks = Editor.marks(editorKit!.raw) || {};
          marks[PROOFREAD_LINE_KEY] = proofreadConfig(item);
          editorKit!.command.exec(PROOFREAD_LINE_KEY, {
            location,
            marks: marks as TextElement,
          });
        });
      });
    } catch (error) {}
  }, [matchItems, langVal]);

  const onSelectionChange = () => {
    // 光标位置改变的时候
    const { selection } = editor!;
    if (selection) {
      try {
        previousTextRef.current = currentTextRef.current;
        // 位置信息
        const [start] = Editor.edges(editor!, selection);
        const { offset, path } = start;
        // 光标所在的叶子节点
        const selectNode = Node.get(editor!, path);
        // 前面的叶子节点的坐标
        const beforePath = Editor.before(editor!, path);
        const beforeNode = beforePath ? Node.get(editor!, beforePath.path) : undefined;
        // 当有下划线的文本在段落最后面的时候
        currentTextRef.current = {
          node: selectNode,
          path: start,
        };
        // 判断时候是否在同一个有下划线的文本下
        if (
          currentTextRef.current?.node?.[PROOFREAD_LINE_KEY]?.id &&
          previousTextRef.current?.node?.[PROOFREAD_LINE_KEY]?.id &&
          currentTextRef.current?.node?.[PROOFREAD_LINE_KEY]?.id === previousTextRef.current?.node?.[PROOFREAD_LINE_KEY]?.id
        ) {
          const currentText = currentTextRef.current.node.text || '';
          const previousText = previousTextRef.current.node.text || '';
          // 在带下划线的文字的末尾添加文本
          if (
            currentText.length === offset &&
            Object.prototype.hasOwnProperty.call(selectNode, PROOFREAD_LINE_KEY) &&
            currentText.length > previousText.length &&
            currentText.indexOf(previousText) === 0
          ) {
            HistoryEditor.withoutSaving(editor!, () => {
              const config = previousTextRef.current.node[PROOFREAD_LINE_KEY];
              Transforms.unsetNodes(editor!, PROOFREAD_LINE_KEY, {
                at: path,
              });
              const marks = Editor.marks(editorKit!.raw) || {};
              let location;

              if (!beforePath || Object.prototype.hasOwnProperty.call(beforeNode, PROOFREAD_LINE_KEY) || path[0] !== beforePath.path[0]) {
                location = {
                  anchor: {
                    path,
                    offset: 0,
                  },
                  focus: {
                    path,
                    offset: previousTextRef.current.path.offset,
                  },
                };
              } else {
                location = {
                  anchor: beforePath,
                  focus: {
                    path: beforePath?.path,
                    offset: beforePath.offset + Number(previousTextRef.current.path.offset),
                  },
                };
              }
              marks[PROOFREAD_LINE_KEY] = config;
              editorKit!.command.exec(PROOFREAD_LINE_KEY, {
                location,
                marks: marks as TextElement,
              });
            });
          }
        }
      } catch (error) {}
    }
  };

  useDebounceEffect(onSelectionChange, [editor?.selection], { wait: 200, leading: true });

  useFocusWithin(editableRef, {
    onFocus: () => {
      try {
        const { selection } = editor!;
        if (selection) {
          // 位置信息
          const [start] = Editor.edges(editor!, selection);
          const { path } = start;
          // 光标所在的叶子节点
          const selectNode = Node.get(editor!, path);
          currentTextRef.current = {
            node: selectNode,
            path: start,
          };
        }
      } catch (error) {}
    },
    onBlur: () => {
      currentTextRef.current = undefined;
      previousTextRef.current = undefined;
    },
  });

  useEffect(() => {
    if (editorContent?.plain) {
      collectEvent?.([
        {
          event: ProofreadingEvent.CLICK_OPEN_ROOFREADING,
          params: {
            moduleName: ModuleName.AI_PROOFREADING,
          },
        },
      ]);
    }
    timeRef.current = dayjs().unix();
    collectEvent?.([
      {
        event: ProofreadingEvent.PROOFREADING_DRAWER_OPEN,
        params: {
          moduleName: ModuleName.AI_PROOFREADING,
        },
      },
    ]);
    return () => {
      clearAllStyle();
      setMatchItems([]);
      collectEvent?.([
        {
          event: ProofreadingEvent.PROOFREADING_DRAWER_CLOSE,
          params: {
            moduleName: ModuleName.AI_PROOFREADING,
            duration: `${dayjs().unix() - timeRef.current}`,
          },
        },
      ]);
    };
  }, []);

  const renderStatus = () => {
    if (isProofreading) {
      return (
        <div className="proofreading-start-state flex justify-center flex-col items-center">
          <IconProofreadingStartState />
          <span className="proofreading-state-desc">
            <IconLoading />
            {locale.under_proofreading}
          </span>
        </div>
      );
    }
    if (!editorContent?.plain) {
      return (
        <div className="proofreading-start-state flex justify-center flex-col items-center">
          <IconProofreadingStartState />
          <span className="proofreading-state-desc">{locale.proofreading_start_state}</span>
        </div>
      );
    }
    if (hasProofreaded) {
      return (
        <div className="proofreading-start-state flex justify-center flex-col items-center">
          <IconProofreadingEmptyState />
          <span className="proofreading-state-desc">{locale.proofreading_no_content}</span>
        </div>
      );
    }
    return (
      <div className="proofreading-start-state flex justify-center flex-col items-center">
        <IconProofreadingStartState />
        <span className="proofreading-state-desc">{locale.proofreading_go_start}</span>
      </div>
    );
  };

  // 清除所有内容
  const clearAllStyle = () => {
    try {
      HistoryEditor.withoutSaving(editor!, () => {
        Transforms.unsetNodes(editor!, PROOFREAD_LINE_KEY, {
          at: [],
          match: node => Object.prototype.hasOwnProperty.call(node, PROOFREAD_LINE_KEY),
        });
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!editorContent?.plain) {
      // 这里是为了防止剩下一条带下划线的text，清空之后输入文字依然带下划线
      // clearAllStyle();
      setMatchItems([]);
      setHasProofreaded(() => false);
    }
  }, [editorContent?.plain]);

  return (
    <div className="proofreading">
      <div className="proofreading-title">
        {locale.proofreading_title}
        <Button
          className="proofreading-btn-reload"
          type="primary"
          size="mini"
          icon={<IconRefresh className="proofreading-btn-reload-icon" />}
          onClick={() => {
            runFetchProofreading();

            collectEvent?.([
              {
                event: ProofreadingEvent.CLICK_ROOFREADING,
                params: {
                  moduleName: ModuleName.AI_PROOFREADING,
                },
              },
            ]);
          }}
        >
          {locale.proofreading}
        </Button>
      </div>
      <div className="proofreading-body">
        <div className="proofreading-content">
          {matchItems.length > 0 ? (
            <>
              <SummarizeTab
                items={matchItems}
                mainCatalog={mainCatalog}
                subCatalog={subCatalog}
                locale={locale}
                onCatalogChange={(type, info) => {
                  if (type === 'main') {
                    setMainCatalog(() => info || []);
                  }
                }}
                onIgnore={(type, info) => {
                  if (type === 'total') {
                    clearAllStyle();
                    setMatchItems([]);
                    return;
                  }
                  const ignoreItems: PCMatchParagraph[] = [];
                  const newItems = matchItems.filter(item => {
                    if ((type === 'main' && item.issue?.category === info) || (type === 'sub' && item.issue?.subcategory === info)) {
                      ignoreItems.push(item);
                      return false;
                    }
                    return true;
                  });
                  ignoreItems.forEach(item => {
                    Transforms.unsetNodes(editor!, PROOFREAD_LINE_KEY, {
                      at: [],
                      match: node => {
                        if (Object.prototype.hasOwnProperty.call(node, PROOFREAD_LINE_KEY) && (node as any)[PROOFREAD_LINE_KEY].id === item.id) {
                          return true;
                        }
                        return false;
                      },
                    });
                  });
                  setMatchItems(() => cloneDeep(newItems));
                }}
              />
              <Collapse
                accordion
                className="proofreading-collapse"
                onChange={(key: string) => {
                  if (activeKey === key) {
                    setActiveKey(undefined);
                    return;
                  }
                  setActiveKey(key);
                }}
                activeKey={activeKey}
              >
                {checkList}
              </Collapse>
            </>
          ) : (
            <>{renderStatus()}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProofreadingDrawer;
