import { Tooltip, TooltipProps } from '@arco-design/web-react';
import { IconClear, IconCloseButton } from '@arco-iconbox/react-hkgai-government-icon';
import cls from 'classnames';
import { FC } from 'react';

interface AiChatBotHeadPops {
  title?: string;
  clearButtonText?: string;
  tooltipContent?: string;
  tooltipProps?: TooltipProps;
  disabled?: boolean;
  loading?: boolean;
  isNewConversation?: boolean;
  cancelable?: boolean;
  onClear?: () => void;
  onClose?: () => void;
}

const AiChatBotHead: FC<AiChatBotHeadPops> = ({ onClear, onClose, loading, clearButtonText, tooltipContent, tooltipProps, disabled = false, isNewConversation = true }) => {
  return (
    <div className="ai-chat-bot-head">
      {/* {isNewConversation ? <div className="ai-chat-bot-head_title">AI Bot</div> : null} */}
      <div className="flex-block"></div>
      {isNewConversation ? null : (
        <div className="ai-chat-bot-head__content">
          <div className={cls('clear-button', { 'clear-button-dis': !disabled })} onClick={onClear}>
            <IconClear color={'#4E5969'} />
            {clearButtonText}
          </div>
        </div>
      )}
      <Tooltip {...tooltipProps} content={tooltipContent} position="right">
        <div className="close-button" onClick={onClose}>
          <IconCloseButton />
        </div>
      </Tooltip>
      <img className="hkgai-icon-chatbox" src={loading ? require('./static/chatbox.gif') : require('./static/chatbox.png')}></img>
      <div className="hkgai-icon-chatbox_shadow"></div>
    </div>
  );
};

export default AiChatBotHead;
export type { AiChatBotHeadPops };
