import './index.scss';

import { Outlet, useLocation } from '@modern-js/runtime/router';
import { Layout, Alert, Dropdown, Divider } from '@arco-design/web-react';
import { IconFile, IconHkCopilot, IconQuestionCircle, IconVideoPlay } from '@arco-iconbox/react-hkgai-government-icon';
import { useModel } from '@modern-js/runtime/model';
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Notification } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { AuthUserInfo } from '../auth';
import { GlobalContext } from '../context';
import SiderAdvanced from './sider/advanced';
import SiderAiWriting from './sider/ai-writing';
import MultiLanguageDropdown from '@/components/language-dropdown';
import useLocale from '@/hooks/useLocale';
import { getNotification } from '@/api/copilot_api';
import { SidebarStatus, SidebarType } from '@/type/copilot';
import GlobalStore from '@/store/globalStore';
import { AiBotEvent, ModuleName } from '@/config/track.config';
import AiChatBot from '@/components/ai-chat-bot';
import GlobalRightSider from '@/components/global-right-sider';
import { WritingProvider } from '@/context/writingContext';
import AiWritingStore, { SiderType } from '@/store/aiWritingStore';

const { Header, Content, Sider, Footer } = Layout;
const PageLayout = () => {
  const aiBotTimeRef = useRef(dayjs().unix());
  const normalWidth = 240;

  const locale = useLocale();
  const { lang, collectEvent } = useContext(GlobalContext);
  const [siderWidth, setSiderWidth] = useState(normalWidth);

  const [{ sideBar = [], aiBotVisible }, { fetchSideBar, changeActiveAiBot, changeAiBotVisible }] = useModel(GlobalStore);
  const [, { setSiderType }] = useModel(AiWritingStore);
  const [notification, setNotification] = useState<Notification>();
  useLayoutEffect(() => {
    fetchNotification();
    fetchSideBar();
  }, []);

  const [isShowDropdown, isUsageTutorial, isChangeLog, isHaveADWriting, isPersistence] = useMemo(() => {
    const dropdownConfig = sideBar?.find(item => item.type === SidebarType.HK_CHAT);
    const usageTutorialConfig = sideBar?.find(item => item.type === (102 as SidebarType));
    const changeLogConfig = sideBar?.find(item => item.type === (103 as SidebarType));
    const adWritingConfig = sideBar?.find(item => item.type === (2 as SidebarType));
    const persistenceConfig = sideBar?.find(item => item.type === (200 as SidebarType));
    return [
      dropdownConfig?.status === SidebarStatus.ENABLED,
      usageTutorialConfig?.status === SidebarStatus.ENABLED,
      changeLogConfig?.status === SidebarStatus.ENABLED,
      adWritingConfig ? adWritingConfig.status === SidebarStatus.ENABLED : false, // 是否显示大纲写作，默认false不显示
      persistenceConfig ? persistenceConfig.status === SidebarStatus.ENABLED : false, // 是否开启持久化存储，默认false不开启
    ];
  }, [sideBar?.length]);
  const urlMap: any = {
    faq: {
      'zh-CN': 'https://hkgpt-application.sg.larksuite.com/docx/Nxb7dxqxIoM2G5xSV9tlnDDmghd',
      'en-US': 'https://hkgpt-application.sg.larksuite.com/docx/UichdAOCOoku1HxuYvsleEgdgYd',
      'zh-HK': 'https://hkgpt-application.sg.larksuite.com/docx/Az8td1ky1ovAvqxU88MlSf5Ugsf',
    },
    usageTutorialUrl: {
      'zh-CN': 'https://hkgpt-application.sg.larksuite.com/docx/CBeKdHXEHoJwR7xtqLmlbXq3gmb',
      'en-US': 'https://hkgpt-application.sg.larksuite.com/docx/Okv6dIa4eoDaUXx9HB6l47kCgGe',
      'zh-HK': 'https://hkgpt-application.sg.larksuite.com/docx/P55hd8tbAoCzbHxkYcsltTZEgTf',
    },
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/advanced-writing')) {
      setSiderType(SiderType.ADVANCED);
    } else {
      setSiderType(SiderType.AIWRITING);
    }
  }, [location.pathname]);
  const fetchNotification = async () => {
    try {
      const data = await getNotification({});

      const { notification: notificationTemp = {} } = data;
      console.log('fetchNotification data', data);

      setNotification(notificationTemp);
    } catch (error) {}
  };
  const notificationVal = useMemo(() => {
    switch (lang) {
      case 'zh-CN':
        return notification?.contentZh ? notification?.contentZh : notification?.contentEn || '';
      case 'en-US':
        return notification?.contentEn || '';
      case 'zh-HK':
        return notification?.contentZhHk ? notification.contentZhHk : notification?.contentEn || '';
      default:
        return notification?.contentEn || '';
    }
  }, [notification, lang]);

  const DropdownList = (
    <div className="head-dropdown-list">
      <div
        className="head-dropdown-menu-item"
        onClick={() => {
          window.open('https://chat.hkgai.net', '_blank');
        }}
      >
        Explore HKChat
      </div>
    </div>
  );
  return (
    <>
      <WritingProvider>
        <Layout className="h-screen w-screen min-w-[1280px] flex flex-col page-ai-bg overflow-hidden">
          <Header className="bg-white custom-header">
            <div className="layout-head">
              {isShowDropdown ? (
                <Dropdown droplist={DropdownList} trigger="click">
                  <div className="icon-copilot-wrap">
                    <IconHkCopilot style={{ width: '129px', height: '36px', cursor: 'pointer' }} />
                  </div>
                </Dropdown>
              ) : (
                <div className="icon-copilot-wrap">
                  <IconHkCopilot style={{ width: '129px', height: '36px', cursor: 'pointer' }} />
                </div>
              )}
              <div className="layout-head-actions">
                {isUsageTutorial ? (
                  <div
                    className="layout-head-actions__button"
                    onClick={() => {
                      window.open(urlMap.usageTutorialUrl[(lang as string) || 'en-US'], '_black');
                    }}
                  >
                    <IconVideoPlay />
                    <div>{locale.layout_head_actions_guidelines}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="layout-head-content">
                  {isChangeLog && (
                    <div
                      className="layout-head-actions__button"
                      onClick={() => {
                        window.open('https://hkgpt-application.sg.larksuite.com/docx/Qre0dlbw8obzJ9xNjKDlTuBHgVg', '_black');
                      }}
                    >
                      <IconFile />
                      <div>{locale.layout_head_actions_change_log}</div>
                    </div>
                  )}

                  <div
                    className="layout-head-actions__button"
                    onClick={() => {
                      window.open(urlMap.faq[(lang as string) || 'en-US'], '_black');
                    }}
                  >
                    <IconQuestionCircle />
                    <div>FAQ</div>
                  </div>
                </div>
              </div>
              <div className="layout-head-extra">
                <MultiLanguageDropdown />
                <AuthUserInfo />
              </div>
            </div>
            <div className="bg-[#F2F2F2] h-2"></div>
          </Header>
          <Content className="bg-[#F2F2F2] p-0 flex-1 overflow-hidden">
            <Layout className="h-full">
              <Sider className={'sider-layout'} collapsible trigger={null} width={siderWidth} collapsedWidth={siderWidth}>
                <SiderAiWriting isHaveADWriting={isHaveADWriting} isPersistence={isPersistence} />
                {isHaveADWriting ? <SiderAdvanced /> : <div></div>}
              </Sider>
              <Divider className="m-0" type="vertical" />
              <Content>
                <Outlet />
              </Content>
              <Sider className="globalRightSider w-fit z-10" width={'fit-content'}>
                <GlobalRightSider />
              </Sider>
            </Layout>
          </Content>
        </Layout>

        <div className="alert-warning-wrap" style={{ display: notificationVal?.length > 0 ? 'block' : 'none' }}>
          <Alert type="warning" content={notificationVal} closable />
        </div>
        <AiChatBot
          visible={aiBotVisible}
          onClose={() => {
            changeAiBotVisible(false);
            changeActiveAiBot(false);
            collectEvent?.([
              {
                event: AiBotEvent.CLICK_HIDE_AI_BOT,
                params: {
                  moduleName: ModuleName.AI_BOT,
                  duration: `${dayjs().unix() - aiBotTimeRef.current}`,
                },
              },
            ]);
          }}
        />
      </WritingProvider>
    </>
  );
};
export default PageLayout;
