import type { TooltipProps } from '@arco-design/web-react';
import { CSSProperties, FC, ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { Input, Tooltip } from '@arco-design/web-react';
import { IconAttachment, IconPaperPlaneRightBlue, IconSendBtn, IconStopCircle, IconAiBotQuote, IconCloseButton } from '@arco-iconbox/react-hkgai-government-icon';

/**
 * bottomSlot       底部插槽                 React.ReactNode
 * inputBoxStyle    输入框样式               CSSProperties
 * placeholder      输入框占位符              String
 * topSlot          顶部部插槽               React.ReactNode
 * showStopGenerate 是否展示停止生成按钮       boolean
 * uploadTipProps   上传组件提示属性, 详情参考  Tooltip TooltipProps
 * onStopGenerator  点击停止生成按钮时触发 () => void
 * onMessageSend    发送消息时触发  (content: string, quote: string) => void
 * onUpload   点击上传按钮时触发 () => void
 */

interface AiChatBotFooterProps {
  inputValue?: string;
  replyQuote?: string;
  bottomSlot?: ReactNode;
  inputBoxStyle?: CSSProperties;
  placeholder?: string;
  showStopGenerate?: boolean;
  topSlot?: ReactNode;
  uploadTipProps?: TooltipProps;
  onClearQuote?: () => void;
  onStopGenerator?: () => void;
  onMessageSend?: (value: string, quote: string) => void;
  onUpload?: () => void;
  onInputChange?: (value: string) => void;
}

const AiChatBotFooter = forwardRef((props: AiChatBotFooterProps, ref) => {
  const {
    bottomSlot,
    inputBoxStyle,
    placeholder,
    showStopGenerate,
    topSlot,
    uploadTipProps,
    inputValue = undefined,
    replyQuote = '',
    onClearQuote,
    onStopGenerator,
    onMessageSend,
    onUpload,
    onInputChange,
  } = props;
  const textareaRef = useRef<any>(null);

  const [cursorIndex, setCursorIndex] = useState(0);

  useImperativeHandle(ref, () => ({
    onFocus: () => {
      textareaRef.current.focus();
    },
  }));

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current?.focus?.();
    }
  }, [textareaRef?.current]);

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef?.current?.setSelectionRange?.(cursorIndex, cursorIndex);
    }
  }, [cursorIndex, textareaRef?.current]);

  const inputChange = (value: string) => {
    onInputChange?.(value);
  };

  const handleMessageGenerate = () => {
    // 生成中不能重复提交
    if (showStopGenerate || !inputValue) {
      return;
    }
    onMessageSend?.(inputValue || '', replyQuote);
    onInputChange?.('');
    onClearQuote?.();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // 定义键盘按下事件的处理函数
  const handleKeyUp = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter' && event.shiftKey) {
      const textarea = event.target;
      const { selectionStart = 0, selectionEnd = 0 } = textarea;
      onInputChange?.(`${inputValue?.slice(0, selectionStart)}${`\n`}${inputValue?.slice(selectionEnd)}`);
      setCursorIndex(() => Number(selectionStart) + 1);
    } else if (event.key === 'Enter') {
      handleMessageGenerate();
    }
  };

  return (
    <div className="ai-chat-bot-footer-wap">
      {topSlot && <div className="ai-chat-bot-footer-top-slot">{topSlot}</div>}
      <div className="ai-chat-bot-footer__content">
        {replyQuote ? (
          <div className="reply-quote">
            <IconAiBotQuote
              style={{
                fontSize: '18px',
                color: '#4E5969',
                flexShrink: 0,
                marginRight: '8px',
              }}
            />
            <div className="quote-text">{replyQuote}</div>
            <IconCloseButton
              style={{
                marginLeft: '6px',
                fontSize: '16px',
                flexShrink: 0,
                cursor: 'pointer',
              }}
              onClick={onClearQuote}
            />
          </div>
        ) : null}
        <div className="ai-chat-bot-footer-input">
          {/* <Tooltip {...uploadTipProps}>
            <div className="button-content" onClick={onUpload}>
              <IconAttachment />
            </div>
          </Tooltip> */}
          <Input.TextArea
            disabled={showStopGenerate}
            className="ai-chat-bot-footer-input-inner"
            ref={textareaRef}
            // maxLength={1000}
            autoSize={{ minRows: 1, maxRows: 3 }}
            placeholder={placeholder}
            onChange={inputChange}
            value={inputValue}
            style={inputBoxStyle}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
          {showStopGenerate ? (
            <div className="button-content" onClick={onStopGenerator}>
              <IconStopCircle />
            </div>
          ) : (
            <>
              {inputValue ? (
                <div className="button-content" onClick={handleMessageGenerate}>
                  <IconPaperPlaneRightBlue />
                </div>
              ) : (
                <div className="button-content">
                  <IconSendBtn />
                </div>
              )}
            </>
          )}
        </div>
        {bottomSlot && <div className="ai-chat-bot-footer-bottom-slot">{bottomSlot}</div>}
      </div>
    </div>
  );
});
export default AiChatBotFooter;
export type { AiChatBotFooterProps };
