import './index.scss';

import { IconArrowLineRight, IconArrowsClockwiseSync, IconMagicPencil } from '@arco-iconbox/react-hkgai-government-icon';
import { FC, useEffect, useState } from 'react';
import useLocale from '@hooks/useLocale';
import cls from 'classnames';
import { getRandomGovFaqs } from './prompt';

interface AiBotShuffleCaseProps {
  title?: string;
  onMessageSend?: (value: string) => void;
  onRefresh?: () => void;
}

const AiBotShuffleCase: FC<AiBotShuffleCaseProps> = ({ onMessageSend, onRefresh, title }) => {
  const locale = useLocale();
  let timeoutId: NodeJS.Timeout;

  const [govFaqs, setGovFaqs] = useState<string[]>(getRandomGovFaqs([]));
  const [loading, setLoading] = useState(false);

  const onRandomShuffle = () => {
    setLoading(() => true);
    timeoutId = setTimeout(() => {
      setLoading(() => false);
      setGovFaqs(() => getRandomGovFaqs(govFaqs));
    }, 500);
    onRefresh?.();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="ai-bot-shuffle-case">
      <div className="ai-bot-shuffle-case__title">
        <div>{title}</div>
        <div className={'clockwise-button-sync'} onClick={onRandomShuffle}>
          <IconArrowsClockwiseSync className={cls({ 'icon-rotate': loading })} />
        </div>
      </div>
      <div className="ai-bot-shuffle-case__list">
        {govFaqs.map(sc => (
          <div className="shuffle-case" key={sc} onClick={() => onMessageSend?.(locale[sc])}>
            <div className="shuffle-case__title">
              <IconMagicPencil color="#4E5969" />
              <div>{locale[sc]}</div>
            </div>
            <IconArrowLineRight />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AiBotShuffleCase;
export type { AiBotShuffleCaseProps };
