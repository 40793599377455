import './index.scss';
import { FC, useState } from 'react';
import { Form, Input, Upload, Button, Message, Image, Modal } from '@arco-design/web-react';
import { IconDelete, IconEye, IconPlus } from '@arco-design/web-react/icon';
import { UploadItem, UploadListProps } from '@arco-design/web-react/es/Upload';
import { IconPdf } from '@arco-iconbox/react-hkgai-fe-icon';
import useLocale from '@/hooks/useLocale';
import { getFileExt } from '@/utils/getFileExt';
import { sendGlobalFeedback } from '@/api/copilot_api';

const FILE_ACCEPT = ['.pdf', '.png', '.jpg', '.jpeg'];

const FeedbackDrawer: FC = () => {
  const locale = useLocale();
  const [form] = Form.useForm();
  const content = Form.useWatch('content', form);
  const images = Form.useWatch('images', form);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onBeforeUpload = (file: File) => {
    const maxFileSize = 30 * 1024 * 1024; // 100MB in bytes
    if (file.size > maxFileSize) {
      Message.error(locale.feedback_input_upload_file_size_limit.replace(/{fieSize}/, maxFileSize / 1024 / 1024));
      return false;
    }

    const fileExt = getFileExt(file?.name);
    // 检查图片类型是否合法
    if (!FILE_ACCEPT?.includes(fileExt.toLowerCase())) {
      Message.error(locale.feedback_input_upload_file_type_limit.replace(/{ext}/, FILE_ACCEPT.join(', ').replace(/\./g, '')));
      return false;
    }
    return true;
  };

  // 产品反馈
  const handleSubmit = async () => {
    setConfirmLoading(() => true);
    try {
      await sendGlobalFeedback(content, images);
      Message.success(locale.feedback_submitted_message);
      form.resetFields();
    } catch (error: any) {
      Message.error(`Upload failed with code: ${error.code}  msg:${error.msg}`);
    } finally {
      setConfirmLoading(() => false);
    }
  };

  const isAssetTypeAnImage = (ext: string) => {
    return ['.jpg', '.jpeg', '.png'].includes(ext);
  };

  const renderUploadList = (filesList: UploadItem[], props: UploadListProps) => {
    return (
      <div className="render-upload-list">
        {filesList?.map(file => {
          const url = file.url || URL.createObjectURL(file.originFile as File);
          const fileExt = getFileExt(file?.name as string);
          return (
            <div key={file.uid} className="upload-item">
              {isAssetTypeAnImage(fileExt) ? (
                <>
                  <Image src={url} className="upload-image-preview" preview={false} />
                  <div className="image-preview-mask">
                    <IconEye
                      onClick={() => {
                        props?.onPreview?.(file);
                      }}
                    />
                    <IconDelete
                      onClick={() => {
                        props?.onRemove?.(file);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <IconPdf style={{ fontSize: '64px' }} />
                  <div className="image-preview-mask">
                    <IconDelete
                      onClick={() => {
                        props?.onRemove?.(file);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="feedback">
      <div className="feedback-title">{locale.feedback_title}</div>
      <div className="feedback-sub-title">{locale.feedback_sub_title}</div>
      <Form layout="vertical" className="feedback-from" form={form}>
        <Form.Item label={locale.feedback_module_name_one} field="content">
          <Input.TextArea allowClear showWordLimit maxLength={1000} placeholder={locale.feedback_textarea_placeholder} />
        </Form.Item>
        <Form.Item label={locale.feedback_module_name_tow} field="images" triggerPropName="fileList">
          <Upload
            beforeUpload={onBeforeUpload}
            multiple={false}
            listType="picture-card"
            limit={{ maxCount: 1, hideOnExceedLimit: false }}
            onPreview={file => {
              Modal.info({
                icon: null,
                content: <Image src={file.url || URL.createObjectURL(file.originFile as File)} preview={false} />,
              });
            }}
            renderUploadList={renderUploadList}
          >
            <div className="upload-target">
              <IconPlus />
              <div>{locale.feedback_input_upload_placeholder}</div>
              <div className="upload-tips">{locale.feedback_input_upload_file_type_limit.replace(/{ext}/, FILE_ACCEPT.join(', ').replace(/\./g, ''))}</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>

      <div className="button-box">
        <Button long type="primary" size="large" disabled={!(content || images)} onClick={handleSubmit} loading={confirmLoading}>
          {locale.feedback_form_submit}
        </Button>
      </div>
    </div>
  );
};

export default FeedbackDrawer;
