import './index.scss';

import { Outlet, useLocation } from '@modern-js/runtime/router';
import { Layout, Alert, Dropdown, Divider, Image, Link } from '@arco-design/web-react';
import { IconFile, IconQuestionCircle, IconVideoPlay } from '@arco-iconbox/react-hkgai-government-icon';
import { useModel } from '@modern-js/runtime/model';
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Notification } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { AuthUserInfo } from '../auth';
import { GlobalContext } from '../context';
import SiderAdvanced from './sider/advanced';
import SiderAiWriting from './sider/ai-writing';
import MultiLanguageDropdown from '@/components/language-dropdown';
import useLocale from '@/hooks/useLocale';
import { getNotification } from '@/api/copilot_api';
import { SidebarStatus, SidebarType } from '@/type/copilot';
import GlobalStore from '@/store/globalStore';
import { AiBotEvent, ModuleName } from '@/config/track.config';
import AiChatBot from '@/components/ai-chat-bot';
import GlobalRightSider from '@/components/global-right-sider';
import { WritingProvider } from '@/context/writingContext';
import AiWritingStore, { SiderType } from '@/store/aiWritingStore';
import Logo from '@/components/logo';
import surveyBannerSrc from '@/static/survey-banner.jpg';

const { Header, Content, Sider } = Layout;
const PageLayout = () => {
  const aiBotTimeRef = useRef(dayjs().unix());
  const normalWidth = 240;

  const locale = useLocale();
  const { lang, collectEvent } = useContext(GlobalContext);

  const [{ sideBar = [], aiBotVisible }, { fetchSideBar, changeActiveAiBot, changeAiBotVisible }] = useModel(GlobalStore);
  const [, { setSiderType }] = useModel(AiWritingStore);
  const [notification, setNotification] = useState<Notification>();
  useLayoutEffect(() => {
    fetchNotification();
    fetchSideBar();
  }, []);

  // sideBar 是一个对象数组，优化查找方式
  const [isShowDropdown, isUsageTutorial, isChangeLog, isHaveADWriting, isPersistence, isShowSurvey] = useMemo(() => {
    const configMap = sideBar?.reduce((acc, item) => {
      acc[item.type] = item.status === SidebarStatus.ENABLED;
      return acc;
    }, {} as Record<string, boolean>);

    return [configMap[SidebarType.HK_CHAT] ?? false, configMap[102] ?? false, configMap[103] ?? false, configMap[2] ?? false, configMap[200] ?? false, configMap[600] ?? false];
  }, [sideBar]);

  const urlMap: any = {
    faq: {
      'zh-CN': 'https://wiki.hkgai.org/s/d28308a8-10ed-4ef4-983e-577b193fc917',
      'en-US': 'https://wiki.hkgai.org/s/3e9b1c0b-c13c-4da2-85d8-7ff1280f281e',
      'zh-HK': 'https://wiki.hkgai.org/s/364b5031-bc82-4a50-94cb-969e8065531b',
    },
    usageTutorialUrl: {
      'zh-CN': 'https://wiki.hkgai.org/s/a32f02c5-3312-41f2-9f05-c9dab2a9682d',
      'en-US': 'https://wiki.hkgai.org/s/a1d2dc1a-556b-441e-a02f-fb44abbff6e6',
      'zh-HK': 'https://wiki.hkgai.org/s/127f1e68-7659-4caf-bf59-e6bd35e53a62',
    },
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/advanced-writing')) {
      setSiderType(SiderType.ADVANCED);
    } else {
      setSiderType(SiderType.AIWRITING);
    }
  }, [location.pathname]);
  const fetchNotification = async () => {
    try {
      const data = await getNotification({});

      const { notification: notificationTemp = {} } = data;
      console.log('fetchNotification data', data);

      setNotification(notificationTemp);
    } catch (error) {}
  };
  const notificationVal = useMemo(() => {
    switch (lang) {
      case 'zh-CN':
        return notification?.contentZh ? notification?.contentZh : notification?.contentEn || '';
      case 'en-US':
        return notification?.contentEn || '';
      case 'zh-HK':
        return notification?.contentZhHk ? notification.contentZhHk : notification?.contentEn || '';
      default:
        return notification?.contentEn || '';
    }
  }, [notification, lang]);

  const DropdownList = (
    <div className="head-dropdown-list">
      <div
        className="head-dropdown-menu-item"
        onClick={() => {
          window.open('https://chat.hkgai.net', '_blank');
        }}
      >
        Explore HKChat
      </div>
    </div>
  );
  return (
    <>
      <WritingProvider>
        <Layout className="h-screen w-screen min-w-[1280px] flex flex-col page-ai-bg overflow-hidden">
          <Header className="bg-white custom-header">
            <div className="layout-head">
              {isShowDropdown ? (
                <Dropdown droplist={DropdownList} trigger="click">
                  <div className="icon-copilot-wrap">
                    {/* <IconHkCopilot style={{ width: '129px', height: '36px', cursor: 'pointer' }} /> */}

                    <Logo suffixStyle={{ position: 'relative', bottom: '6px' }} />
                  </div>
                </Dropdown>
              ) : (
                <div className="icon-copilot-wrap">
                  <Logo />
                </div>
              )}
              <div className="layout-head-actions">
                {isUsageTutorial ? (
                  <Link className="layout-head-actions__button" href={urlMap.usageTutorialUrl[(lang as string) || 'en-US']} target="_blank">
                    <IconVideoPlay />
                    <div>{locale.layout_head_actions_guidelines}</div>
                  </Link>
                ) : (
                  <div></div>
                )}
                <div className="layout-head-content">
                  {isShowSurvey && (
                    <div
                      className="layout-head-actions__button"
                      onClick={() => {
                        window.open('/survey', '_black');
                      }}
                    >
                      <Image src={surveyBannerSrc} preview={false} />
                    </div>
                  )}

                  {isChangeLog && (
                    <Link className="layout-head-actions__button" href="https://hkgpt-application.sg.larksuite.com/docx/Qre0dlbw8obzJ9xNjKDlTuBHgVg" target="_blank">
                      <IconFile />
                      <div>{locale.layout_head_actions_change_log}</div>
                    </Link>
                  )}

                  <Link className="layout-head-actions__button" target="_blank" href={urlMap.faq[(lang as string) || 'en-US']}>
                    <IconQuestionCircle />
                    <div>FAQ</div>
                  </Link>
                </div>
              </div>
              <div className="layout-head-extra">
                <MultiLanguageDropdown />
                <AuthUserInfo />
              </div>
            </div>
            <div className="bg-[#F2F2F2] h-2"></div>
          </Header>
          <Content className="bg-[#F2F2F2] p-0 flex-1 overflow-hidden">
            <Layout className="h-full">
              <Sider className={'sider-layout'} collapsible trigger={null} width={normalWidth} collapsedWidth={normalWidth}>
                <SiderAiWriting isHaveADWriting={isHaveADWriting} isPersistence={isPersistence} />
                {isHaveADWriting ? <SiderAdvanced /> : <div></div>}
              </Sider>
              <Divider className="m-0" type="vertical" />
              <Content>
                <Outlet />
              </Content>
              <Sider className="globalRightSider w-fit z-10" width={'fit-content'}>
                <GlobalRightSider />
              </Sider>
            </Layout>
          </Content>
        </Layout>

        <div className="alert-warning-wrap" style={{ display: notificationVal?.length > 0 ? 'block' : 'none' }}>
          <Alert type="warning" content={notificationVal} closable />
        </div>
        <AiChatBot
          visible={aiBotVisible}
          onClose={() => {
            changeAiBotVisible(false);
            changeActiveAiBot(false);
            collectEvent?.([
              {
                event: AiBotEvent.CLICK_HIDE_AI_BOT,
                params: {
                  moduleName: ModuleName.AI_BOT,
                  duration: `${dayjs().unix() - aiBotTimeRef.current}`,
                },
              },
            ]);
          }}
        />
      </WritingProvider>
    </>
  );
};
export default PageLayout;
