export enum ProofreadCatalog {
  COMMON = 'COMMON',
  PROPERNOUN = 'PROPERNOUN',
  VALUES = 'VALUES',
}

export enum PCommonCatalog {
  PUNCTUATION = 'Punctuation',
  TYPO = 'Typo',
  GRAMMAR = 'Grammar',
}

export enum PPropernounCatalog {
  NAME = 'Name',
  ORG = 'Org',
  EVENT = 'Event',
}

export enum PValuesCatalog {
  SENSITIVE = 'Sensitive',
}

export interface CheckParagraph {
  index?: number;
  content?: string;
}

export interface ProofreadingCheckReq {
  text: CheckParagraph[]; // The text to be checked. This is required.
  language?: string; // optional. language code：en, zh-hans, zh-hant
  systemLanguage?: string; // language from system setting
  enabledCategories?: string; // optional. categories to be enabled, comma-separated"
  enabledOnly?: boolean; // "If true, only the categories that are specified with `enabledCategories` are enabled."
}

export interface PCReplacement {
  value?: string;
}

export enum PCCategory {
  PROPER_NAMES = 'PROPERNOUN',
  COMMON_ERRORS = 'COMMON',
}

export enum PCSubcategoryCommon {
  ORGANIZATIONS = 'Org',
  PERSONS = 'Name',
  EVENTS = 'Event',
}

export enum PCSubcategoryProperNoun {
  TYPO = 'Typo',
  GRAMMAR = 'Grammar',
  PUNCTUATION = 'Punctuation',
}

export interface PCIssue {
  description?: PCDescription;
  category?: string;
  subcategory?: string;
}

export enum ProofreadingLanguageType {
  ZH_CHS = 'zh-hans', //  简体
  ZH_CHT = 'zh-hant', //  繁体
  EN = 'en', //  英语
}

export type PCDescription = {
  [key in ProofreadingLanguageType]: string;
};

export interface PCMatch {
  offset?: number;
  length?: number;
  replacements?: PCReplacement[] | null;
  issue?: PCIssue;
}
export interface ProofreadingCheckResponse {
  results?: ProofreadingCheckResult[];
}

export interface ProofreadingCheckResult {
  index?: number;
  matches: PCMatch[] | null;
}

export interface PCMatchParagraph extends PCMatch {
  paragraph: number;
  text: string;
  id: string;
}

export enum PROPERNOUN {}
